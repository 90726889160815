import React, {useState} from 'react'
import {withStyles} from '@material-ui/styles'
import {
  Paper,
  Button,
  Avatar,
  Grid,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@material-ui/core'
import cx from 'classnames'
import {ChoiceTypography, styles} from './styles/CourseItem.choice.styles.js'

function CourseItemChoice({
  classes,
  item,
  hasMinDurationPassed,
  onCourseItemComplete,
  isCompletingItem,
}) {
  const [currentChoiceIndex, setCurrentChoiceIndex] = useState(0)
  const [choice, setChoice] = useState('')

  const choices = item?.choices || []
  const currentChoice = choices[currentChoiceIndex]

  const handleChange = event => {
    setChoice(event.target.value)
  }

  const handleNextClick = () => {
    if (currentChoiceIndex < choices.length - 1) {
      setCurrentChoiceIndex(prevIndex => prevIndex + 1)
      setChoice('')
    } else {
      onCourseItemComplete(item, parseInt(choice, 10))
    }
  }

  if (!currentChoice) {
    return <div className={classes.root}>No questions available.</div>
  }

  return (
    <div className={classes.root}>
      <div className={classes.mainCont}>
        <Paper className={classes.textCont}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <ChoiceTypography>{currentChoice.question || ''}</ChoiceTypography>
            </Grid>
            <Grid item xs={12}>
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="question-choice"
                  name="customized-radios"
                  value={choice}
                  onChange={handleChange}
                >
                  {currentChoice.options?.map((option, index) => (
                    <FormControlLabel
                      className={classes.choicesControl}
                      value={option.id.toString()}
                      key={`question-choice-${index}`}
                      control={
                        <Radio
                          color="primary"
                          checked={option.id.toString() === choice}
                          id={`question-choice-${option.id}`}
                          className={classes.radioRoot}
                          icon={
                            <Avatar className={classes.radioIcon}>
                              {String.fromCharCode(97 + index).toUpperCase()}
                            </Avatar>
                          }
                          checkedIcon={
                            <Avatar className={cx(classes.radioIcon, classes.radioCheckedIcon)}>
                              {String.fromCharCode(97 + index).toUpperCase()}
                            </Avatar>
                          }
                        />
                      }
                      label={option.label}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
        </Paper>
        <div className={hasMinDurationPassed ? classes.nextButton : classes.nextButtonDisabled}>
          <Button
            disabled={!hasMinDurationPassed || isCompletingItem}
            onClick={() => {
              if (choice === '') {
                alert('You must select an option to continue.')
                return
              }
              handleNextClick()
            }}
          >
            Next
          </Button>
        </div>
      </div>
    </div>
  )
}

export default withStyles(styles)(CourseItemChoice)
