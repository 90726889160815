import React from 'react'
import cx from 'classnames'
import {Button, CircularProgress} from '@material-ui/core'
import SignatureCanvas from 'react-signature-canvas'
import Tooltip from '@material-ui/core/Tooltip'

export const getImageFromCanvasRef = ref =>
  ref.current && ref.current.getTrimmedCanvas().toDataURL('image/png')

export default class Signature extends React.PureComponent {
  state = {
    empty: true,
    isCanvasEnough: false,
  }

  componentDidUpdate(prevProps, prevState) {
    const {empty} = this.state
    const {setNoSignature} = this.props
    if (empty !== prevState.empty) {
      if (empty) {
        setNoSignature(true)
      } else {
        setNoSignature(false)
      }
    }
  }

  beginSign = () => {
    this.setState({empty: false})
  }

  endSign = () => {
    if (
      this.props.canvasRef.current.getTrimmedCanvas().width >= 60 &&
      this.props.canvasRef.current.getTrimmedCanvas().height >= 35
    ) {
      this.setState({
        isCanvasEnough: true,
      })
    }
  }

  clear = () => {
    this.props.setNoSignature(true)
    this.props.canvasRef.current.clear()
    this.setState({
      empty: true,
      isCanvasEnough: false,
    })
  }

  render() {
    const {classes, isLoading, onSave, disabled} = this.props
    const {empty, isCanvasEnough} = this.state
    return (
      <>
        <div className={cx(classes.signatureContainer, {[classes.signatureDisabled]: disabled})}>
          {!disabled ? (
            <SignatureCanvas
              ref={this.props.canvasRef}
              penColor="black"
              canvasProps={{
                className: classes.canvas,
              }}
              onBegin={this.beginSign}
              onEnd={this.endSign}
            />
          ) : (
            <div className={classes.canvas} />
          )}
          <div className={classes.buttonContainer}>
            <Button
              className={classes.clearButton}
              variant="outlined"
              disabled={empty || isLoading}
              onClick={this.clear}
            >
              Clear
            </Button>
            {onSave && (
              <Tooltip
                placement="top"
                title={isCanvasEnough ? '' : 'Invalid signature. Please try again.'}
              >
                <span>
                  <Button
                    className={classes.saveButton}
                    disabled={empty || !isCanvasEnough || isLoading}
                    onClick={onSave}
                    variant="contained"
                  >
                    Sign
                    {isLoading && <CircularProgress className={classes.progress} size={15} />}
                  </Button>
                </span>
              </Tooltip>
            )}
          </div>
        </div>
      </>
    )
  }
}
