import React, {useEffect} from 'react'
import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import {withStyles} from '@material-ui/styles'
import _ from 'lodash'
import {Typography, IconButton, Paper, Button} from '@material-ui/core'
import cx from 'classnames'
import moment from 'moment'
import DoneIcon from '@material-ui/icons/Done'
import styles from './styles/CourseDetail.index.styles.js'
import * as HeaderActions from '../common/redux/actions.header'
import * as Actions from './redux/actions'
import * as NotificationActions from '../common/redux/actions.notifications'
import AuthenticatedPage from '../common/AuthenticatedPage.jsx'
import AlfLinearProgress from '../common/AlfLinearProgress'
import {Icons, getItemIcon, findNextItem} from './Utils'
import {socialMedia} from '../constants/SocialMedia'

function CourseDetail({
  classes,
  setHeader,
  params,
  getCourse,
  courses,
  setLastCompletedCourseItem,
  router,
  createError,
}) {
  const {isFetching, course, lastCompletedCourseItem} = courses
  const courseImage = course?.task?.type?.image?.fullSize

  useEffect(() => {
    setHeader({hidden: false})
    getCourse(_.get(params, 'id'))
  }, [])

  useEffect(() => {
    const lastCompletedItemId = _.get(course, 'lastCompletedCourseItem', 0)
    const lastCompletedItem = _.find(course?.items, {id: parseInt(lastCompletedItemId, 10)})
    setLastCompletedCourseItem(lastCompletedItem)
  }, [course])

  if (course && _.isEmpty(course.task)) {
    createError('User not assigned to this course.')
    router.push('/')
    setTimeout(() => {
      window.close()
    }, 2000)
  }

  const renderSectionList = items => {
    const lastCompletedCourseOrder = _.get(lastCompletedCourseItem, 'order', null)

    return items.map((item, index) => {
      return (
        <Button
          key={`course-item-${index}`}
          className={classes.sectionListItemCont}
          onClick={() => {
            if (item.order - 1 === lastCompletedCourseOrder) {
              onStartCourse()
            }
          }}
        >
          <img src={getItemIcon(item)} alt="item Icon" />
          <Typography>{item?.title}</Typography>
          {lastCompletedCourseOrder !== null && item.order <= lastCompletedCourseOrder ? (
            <DoneIcon />
          ) : null}
        </Button>
      )
    })
  }

  const onStartCourse = () => {
    const nextItem = lastCompletedCourseItem
      ? findNextItem(lastCompletedCourseItem, course?.items)
      : _.first(course?.items)

    if (!nextItem) {
      if (course.statementRequired && course?.currentScore >= course?.minimumScore) {
        router.push(`/course/${course.id}/signature`)
      } else {
        router.push(`/course/${course.id}/summary`)
      }
      return
    }

    const nextItemId = _.get(nextItem, 'id', null)
    if (nextItemId) {
      router.push(`/course/${_.get(params, 'id')}/item/${nextItemId}`)
    }
  }

  return (
    <div className={classes.root}>
      {isFetching && <AlfLinearProgress />}
      {!_.isEmpty(course) && (
        <div className={classes.mainCont}>
          <img
            className={cx(classes.taskImage, !courseImage && classes.emptyImage)}
            src={courseImage || Icons.imagePlaceholderIcon}
            alt="Course"
          />
          <div className={classes.contentCont}>
            <div className={classes.topRow}>
              <div className={classes.topRowLeftColumn}>
                <Typography component="h1" variant="h1">
                  {course?.name}
                </Typography>
                <Typography>
                  Duration <span>{course?.duration}</span>
                </Typography>
                <Typography>
                  Due By <span>{moment(course?.task?.dueDate).format('MM/DD/YYYY')}</span>
                </Typography>
              </div>
              <div className={classes.topRowRightColumn}>
                <IconButton onClick={() => onStartCourse()}>
                  <img src={Icons.startCourseIcon} alt="start course" />
                </IconButton>
                <Typography>
                  {`${_.get(lastCompletedCourseItem, 'order', 0) > 0 ? 'Continue' : 'Start'}`}{' '}
                  Course
                </Typography>
              </div>
            </div>
            <div className={classes.bottomRow}>
              <div className={classes.bottomRowLeftColumn}>
                <Typography component="h2" variant="h2">
                  Objectives
                </Typography>
                <Typography>{course?.objective}</Typography>
              </div>
              <div className={classes.bottomRowRightColumn}>
                <Paper className={classes.sectionsListCont}>
                  <Typography component="h2" variant="h2">
                    Sections
                  </Typography>
                  <div className={classes.sectionsListScroll}>
                    {renderSectionList(course?.items || [])}
                  </div>
                </Paper>
                <Typography className={classes.infoText}>
                  This is an early release and some features may not work as expected. You may also
                  complete this Course on the mobile app as long as it&apos;s been started on
                  mobile.
                </Typography>
                <div className={classes.storeIcons}>
                  <a href={socialMedia.appStoreURL} target="_blank" rel="noopener noreferrer">
                    <img className={classes.storeIcon} src={Icons.appStoreLogo} alt="App store" />
                  </a>
                  <a href={socialMedia.playStoreURL} target="_blank" rel="noopener noreferrer">
                    <img className={classes.storeIcon} src={Icons.playStoreLogo} alt="Play store" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

CourseDetail.displayName = 'CourseDetail'

function mapStateToProps(state) {
  return {
    courses: _.get(state, 'course'),
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({...Actions, ...HeaderActions, ...NotificationActions}, dispatch)
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withStyles(styles, {withTheme: true}),
  AuthenticatedPage,
)(CourseDetail)
