import {call, put, all, takeEvery} from 'redux-saga/effects'
import _ from 'lodash'
import {push} from 'react-router-redux'
import ProfileApi from '../api'
import * as Actions from './actions'
import * as Types from './actions.types'
import * as AuthActions from '../../auth/redux/actions'
import * as EmailActions from './actions.changeEmail'
import * as EmailActionTypes from './actions.changeEmail.types'
import * as NotificationActions from '../../common/redux/actions.notifications'

export default function* profileFlow() {
  yield all([
    takeEvery(Types.PROFILE_GET, getProfile),
    takeEvery(Types.PROFILE_SAVE, saveProfile),
    takeEvery(Types.PROFILE_CHANGE_PASSWORD, changePassword),
    takeEvery(EmailActionTypes.CHANGE_EMAIL, changeEmail),
    takeEvery(EmailActionTypes.CHANGE_EMAIL_CONFIRM, confirmChangeEmail),
    takeEvery(EmailActionTypes.CHANGE_EMAIL_VERIFY, verifyChangeEmail),
    takeEvery(EmailActionTypes.CHANGE_EMAIL_RESEND_CONFIRM, resendConfirmationEmail),
    takeEvery(EmailActionTypes.CHANGE_EMAIL_RESEND_VERIFY, resendVerifyEmail),
    takeEvery(EmailActionTypes.CHANGE_EMAIL_CANCEL, cancelChangeEmail),
    takeEvery(EmailActionTypes.EMAIL_VERIFY, verifyEmail),
  ])
}

function* getProfile() {
  try {
    const response = yield call(ProfileApi.getProfile)
    yield put(Actions.getProfileSuccess(response))
  } catch (error) {
    yield put(Actions.getProfileFailure(error))
    if (error.response.status === 401) {
      yield put(AuthActions.logout())
    }
  }
}

function* saveProfile(action) {
  const {id, payload} = action

  try {
    const update = convertToApiUpdate(payload)
    const response = yield call(ProfileApi.updateProfile, id, update)
    yield put(Actions.saveProfileSuccess(response))
    yield put(Actions.toggleProfileEdit())
    yield put(NotificationActions.createSuccess('Profile has been updated'))
  } catch (error) {
    yield put(Actions.saveProfileFailure(error))
  }
}

function* changePassword(action) {
  const {currentPassword, newPassword} = action
  try {
    const response = yield call(ProfileApi.changePassword, currentPassword, newPassword)
    yield put(Actions.changePasswordSuccess(response))
    yield put(NotificationActions.createSuccess('Password has been changed'))
  } catch (error) {
    yield put(Actions.changePasswordFailure(error))
  }
}

function* changeEmail(action) {
  const {newEmail} = action
  try {
    yield call(ProfileApi.changeEmailStep1Request, newEmail)
    yield put(EmailActions.changeEmailSuccess())
    yield put(Actions.getProfile())
  } catch (error) {
    yield put(EmailActions.changeEmailFailure(error))
  }
}

function* confirmChangeEmail(action) {
  const {id, token} = action
  try {
    yield call(ProfileApi.changeEmailStep2Confirm, id, token)
    yield put(EmailActions.confirmChangeEmailSuccess())
    yield put(NotificationActions.createSuccess('Change email request has been confirmed'))
    yield put(push('/profile/security'))
  } catch (error) {
    yield put(EmailActions.confirmChangeEmailFailure(error))
  }
}

function* verifyChangeEmail(action) {
  const {id, token} = action
  try {
    yield call(ProfileApi.changeEmailStep3Verify, id, token)
    yield put(EmailActions.verifyChangeEmailSuccess())
    yield put(NotificationActions.createSuccess('Account email has been updated'))
    yield put(push('/profile/security'))
  } catch (error) {
    yield put(EmailActions.verifyChangeEmailFailure(error))
  }
}

function* cancelChangeEmail() {
  try {
    yield call(ProfileApi.cancelChangeEmail)
    yield put(EmailActions.cancelChangeEmailSuccess())
    yield put(Actions.getProfile())
  } catch (error) {
    yield put(EmailActions.cancelChangeEmailFailure(error))
  }
}

function* resendConfirmationEmail() {
  try {
    yield call(ProfileApi.changeEmailResendConfirm)
    yield put(EmailActions.resendConfirmEmailSuccess())
    yield put(NotificationActions.createSuccess('Confirmation email has been sent'))
  } catch (error) {
    yield put(EmailActions.resendConfirmEmailFailure(error))
  }
}

function* resendVerifyEmail() {
  try {
    yield call(ProfileApi.changeEmailResendVerify)
    yield put(EmailActions.resendVerifyEmailSuccess())
    yield put(NotificationActions.createSuccess('Verification email has been sent'))
  } catch (error) {
    yield put(EmailActions.resendVerifyEmailFailure(error))
  }
}

function* verifyEmail(action) {
  const {id, token} = action
  try {
    yield call(ProfileApi.verifyEmail, id, token)
    yield put(EmailActions.verifyEmailSuccess())
    yield put(NotificationActions.createSuccess('Account email has been verified'))
    yield put(push('/'))
  } catch (error) {
    yield put(EmailActions.verifyEmailFailure(error))
  }
}

function convertToApiUpdate(update) {
  const apiUpdate = _.cloneDeep(update)

  delete apiUpdate.avatar

  if (apiUpdate.avatarUpload || apiUpdate.avatarUpload === null) {
    apiUpdate.avatar = apiUpdate.avatarUpload
    delete apiUpdate.avatarUpload
  }

  return apiUpdate
}
