import urlJoin from 'url-join'
import _ from 'lodash'
import isNode from 'detect-node'
import settings from '../../../config/settings'

const appBaseUrl = _.get(settings, 'appBaseURL', '/')

export function getAbsoluteAssetUrl(localUrl) {
  return urlJoin(appBaseUrl, localUrl)
}

export function getPathParam(name) {
  if (isNode) return
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  const token = urlParams.get(name)

  return token
}

export function removeURLParameter(url, parameter) {
  const urlparts = url.split('?')
  if (urlparts.length >= 2) {
    const prefix = encodeURIComponent(parameter) + '='
    const pars = urlparts[1].split(/[&;]/g)

    for (let i = pars.length; i-- > 0; ) {
      if (pars[i].lastIndexOf(prefix, 0) !== -1) {
        pars.splice(i, 1)
      }
    }

    return urlparts[0] + (pars.length > 0 ? '?' + pars.join('&') : '')
  }
  return url
}
