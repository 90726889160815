import React, {useEffect} from 'react'
import PropTypes from 'prop-types'
import {makeStyles} from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'

import _ from 'lodash'
import TimerLinearProgress from '../common/TimerLinearProgress'

function convertToTimeString(time) {
  const secNum = parseInt(time, 10)
  let hours = Math.floor(secNum / 3600)
  let minutes = Math.floor((secNum - hours * 3600) / 60)
  let seconds = secNum - hours * 3600 - minutes * 60

  if (hours < 10) {
    hours = '0' + hours
  }
  if (minutes < 10) {
    minutes = '0' + minutes
  }
  if (seconds < 10) {
    seconds = '0' + seconds
  }
  if (hours > 0) {
    return hours + ':' + minutes + ':' + seconds
  }
  return minutes + ':' + seconds
}

function LinearProgressWithLabel(props) {
  const formattedTimeSec = convertToTimeString(props.timeSec.toString())
  return (
    <Box alignItems="center">
      <Box width="100%" mr={1}>
        <TimerLinearProgress variant="determinate" {...props} />
      </Box>
      <Box
        minWidth={35}
        style={{
          float: 'right',
          marginTop: '4px',
        }}
      >
        <Typography
          style={{
            marginBottom: '16px',
            color: '#69788C',
            fontSize: '14px',
            fontWeight: 'bold',
            letterSpacing: 0,
            lineHeight: '17px',
          }}
          variant="body2"
          color="textSecondary"
        >{`${formattedTimeSec}`}</Typography>
      </Box>
    </Box>
  )
}

LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
}

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
})

export default function TimerLinearWithValueLabel({item, setHasMinDurationPassed, router}) {
  const classes = useStyles()
  const [progress, setProgress] = React.useState(0)
  const [timeSec, setTimeSec] = React.useState(_.get(item, 'minDuration', 0) || 0)
  const progressTick = 100 / _.get(item, 'minDuration', 0) || 0

  const timerFinish = timer => {
    clearInterval(timer)
    setHasMinDurationPassed(true)
  }

  useEffect(() => {
    setTimeSec(_.get(item, 'minDuration', 0) || 0)
    setProgress(0)
    setHasMinDurationPassed(false)

    const timer = setInterval(() => {
      setTimeSec(prevTimeSec => (prevTimeSec <= 0 ? prevTimeSec : prevTimeSec - 1))
      setProgress(prevProgress =>
        prevProgress + progressTick >= 100
          ? 100 && timerFinish(timer)
          : prevProgress + progressTick,
      )
    }, 1000)
  }, [router.location.pathname])

  return (
    <div className={classes.root}>
      <LinearProgressWithLabel value={progress} timeSec={timeSec || 0} />
    </div>
  )
}
