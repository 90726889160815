import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {
  Input,
  Button,
  Typography,
  Grid,
  Divider,
  Icon,
  InputAdornment,
  withStyles,
  TextField,
  IconButton,
  Tooltip,
} from '@material-ui/core'
import {bindActionCreators, compose} from 'redux'
import _ from 'lodash'
import AuthenticatedPage from '../common/AuthenticatedPage.jsx'
import ValidatedComponent from '../common/ValidatedComponent.jsx'
import InputValidator from '../common/InputValidator.jsx'
import Loading from '../common/Loading.jsx'
import ImageEditor from '../common/ImageEditor.jsx'
import UserUtils from '../utils/UserUtils'
import * as Actions from './redux/actions'
import ShareUtils from '../utils/ShareUtils'
import settings from '../../../config/settings'
import {socialMedia} from '../constants/SocialMedia'

const styles = theme => ({
  avatarContainer: {
    padding: theme.spacing(3),
    textAlign: 'center',
  },
  avatar: {
    width: '12em',
    maxWidth: '85%',
    margin: '2.5em auto 0',
    borderRadius: '50%',
    padding: theme.spacing(1),
    boxShadow: theme.shadows[1],
  },
  editControls: {
    paddingTop: theme.spacing(3),
    paddingRight: theme.spacing(3),
    display: 'flex',
    justifyContent: 'flex-end',
  },
  infoContainer: {
    padding: theme.spacing(3),
  },
  infoField: {
    width: '100%',
    margin: theme.spacing(1),
  },
})

class ProfileInfo extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      showEmail: false,
      email: '',
      refLink:
        _.get(settings, 'appBaseURL') + '/signup?referral_code=' + this.props.profile.referralCode,
    }

    this.referralLinkInput = null
    this.setReferralRef = element => {
      this.referralLinkInput = element
    }
    this.onCopyClick = this.onCopyClick.bind(this)
    this.onSaveClick = this.onSaveClick.bind(this)
    this.onShareClick = this.onShareClick.bind(this)
    this.showEmail = this.showEmail.bind(this)
    this.getTwitterShareUrl = this.getTwitterShareUrl.bind(this)
    this.getFbShareUrl = this.getFbShareUrl.bind(this)
    this.getGooglePlusShareUrl = this.getGooglePlusShareUrl.bind(this)
    this.getLinkedinShareUrl = this.getLinkedinShareUrl.bind(this)
  }

  componentDidMount() {
    this.props.initProfileEdit()
  }

  onSaveClick() {
    const {profileEdit, saveProfile, isComponentValid} = this.props

    if (isComponentValid()) {
      saveProfile(profileEdit.id, profileEdit)
    }
  }

  onChange(propName, e) {
    this.setState({[propName]: e.target.value})
  }

  showEmail() {
    this.setState(state => ({
      showEmail: !state.showEmail,
    }))
  }

  render() {
    const {showEmail} = this.state
    const {
      profile,
      profileEdit,
      updateProfile,
      isEditMode,
      isFetching,
      isSaving,
      toggleProfileEdit,
      classes,
    } = this.props

    let content
    if (isEditMode) {
      content = (
        <Grid container>
          <Grid item xs={12} className={classes.editControls}>
            <Button type="button" onClick={this.onSaveClick}>
              Save Profile
            </Button>
            <Button type="button" onClick={() => toggleProfileEdit()}>
              Cancel
            </Button>
          </Grid>
          <Grid item xs={12} sm={4} md={3} className={classes.avatarContainer}>
            <ImageEditor
              src={profileEdit.avatar ? profileEdit.avatar.fullSize : null}
              fallbackImage={UserUtils.getAvatarUploadPlaceholderUrl()}
              disableCrop={true}
              onReset={() => updateProfile({avatarUpload: null, avatar: null})}
              onComplete={avatarUpload => updateProfile({avatarUpload})}
            />
          </Grid>
          <Grid item xs={12} sm={8} md={9} className={classes.infoContainer}>
            <TextField
              className={classes.infoField}
              value={profileEdit.firstName}
              onChange={e => updateProfile({firstName: e.target.value})}
              placeholder="First name*"
              label="First name*"
              margin="normal"
            />
            <TextField
              className={classes.infoField}
              value={profileEdit.lastName}
              onChange={e => updateProfile({lastName: e.target.value})}
              placeholder="Last name*"
              label="Last name*"
              margin="normal"
            />
            <TextField
              className={classes.infoField}
              value={profileEdit.email}
              disabled
              placeholder="Email"
              margin="normal"
              startAdornment={
                <InputAdornment position="start">
                  <Icon className="fa fa-envelope" />
                </InputAdornment>
              }
            />
            {['website', 'facebook', 'instagram', 'linkedin', 'twitter'].map(media => (
              <TextField
                className={classes.infoField}
                key={media}
                value={profileEdit[media]}
                placeholder={socialMedia[media] && socialMedia[media].label}
                margin="normal"
                // startAdornment={
                //   <InputAdornment position="start">
                //     <Icon
                //       className={`fa ${socialMedia[media] && socialMedia[media].fontAwesome}`}
                //     />
                //   </InputAdornment>
                // }
              />
            ))}
          </Grid>
        </Grid>
      )
    } else {
      content = (
        <Grid container>
          <Grid item xs={12} className={classes.editControls}>
            <Button variant="contained" color="primary" onClick={() => toggleProfileEdit()}>
              Edit Profile
            </Button>
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <div className={classes.avatarContainer}>
              <img
                className={classes.avatar}
                src={UserUtils.getImageUrl(profile)}
                onError={e => {
                  e.currentTarget.style.display = 'none'
                }}
                alt=""
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={8} md={9} className={classes.infoContainer}>
            <TextField
              readOnly={true}
              placeholder="First Name"
              label="First Name"
              className={classes.infoField}
              value={profile.firstName}
              margin="normal"
            />
            <TextField
              readOnly={true}
              placeholder="Last Name"
              label="Last Name"
              className={classes.infoField}
              value={profile.lastName}
              margin="normal"
            />
            {['Email', 'Website', 'Facebook', 'Instagram', 'LinkedIN', 'Twitter'].map(media => (
              <TextField
                className={classes.infoField}
                readOnly={true}
                key={media}
                value={profile[media]}
                margin="normal"
                placeholder={socialMedia[media] && socialMedia[media].label}
                label={media}
                // Couldn't figure out how to pass the [media] variable along to InputProps
                // InputProps={{
                //   startAdornment: (
                //     <InputAdornment position="start">
                //       <Icon
                //         className={`fa ${socialMedia[media] && socialMedia[media].fontAwesome}`}
                //       />
                //     </InputAdornment>
                //   ),
                // }}
              />
            ))}
            <br />
            <br />
            <Divider />
            <br />
            <TextField
              readOnly={true}
              label="Referral"
              className={classes.infoField}
              value={this.state.refLink}
              inputRef={this.setReferralRef}
              margin="normal"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Icon className="fa fa-users" />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <Tooltip title="Copy referral code">
                      <IconButton onClick={this.onCopyClick} aria-label="Copy referral code">
                        <Icon className="fa fa-copy" />
                      </IconButton>
                    </Tooltip>
                  </InputAdornment>
                ),
              }}
            />

            <div className={classes.infoField}>
              <Typography variant="body1">Share:</Typography>
              {showEmail ? (
                <IconButton onClick={this.showEmail}>
                  <Icon className="fa fa-envelope-open" />
                </IconButton>
              ) : (
                <IconButton onClick={this.showEmail}>
                  <Icon className="fa fa-envelope" />
                </IconButton>
              )}
              <IconButton
                title="facebook"
                onClick={this.onShareClick.bind(this, this.getFbShareUrl())}
              >
                <Icon className="fa fa-facebook-official" aria-hidden="true" />
              </IconButton>
              <IconButton
                title="twitter"
                onClick={this.onShareClick.bind(this, this.getTwitterShareUrl(), 'twitter')}
              >
                <Icon className="fa fa-twitter" aria-hidden="true" />
              </IconButton>
              <IconButton
                title="share"
                onClick={this.onShareClick.bind(this, this.getLinkedinShareUrl())}
              >
                <Icon className="fa fa-linkedin-square" aria-hidden="true" />
              </IconButton>

              <IconButton
                title="share"
                onClick={this.onShareClick.bind(this, this.getPinterestShareUrl())}
                data-pin-do="IconButtonPin"
              >
                <Icon className="fa fa-pinterest" aria-hidden="true" />
              </IconButton>
              {showEmail ? (
                <React.Fragment>
                  <InputValidator shouldValidateOnBlur={true} className="referral-share-email">
                    <Input
                      type="email"
                      id="referral-share-email"
                      placeholder="Enter friend's email address"
                      required
                      onChange={this.onChange.bind(this, 'email')}
                      value={this.state.email}
                    />
                  </InputValidator>
                  <Button
                    title="share"
                    onClick={this.onShareClick.bind(this, this.getEmailShareUrl(), 'email')}
                  >
                    Send Email
                  </Button>
                </React.Fragment>
              ) : null}
            </div>
          </Grid>
        </Grid>
      )
    }

    return (
      <React.Fragment>
        <div className="row">
          {isFetching || isSaving ? <Loading /> : null}
          {content}
        </div>
      </React.Fragment>
    )
  }

  onCopyClick() {
    this.referralLinkInput.select()
    document.execCommand('copy')
  }

  onShareClick(shareUrl) {
    ShareUtils.showShareWindow(shareUrl)
  }

  getPinterestShareUrl() {
    const shareImage = this.props.profile.avatar.fullSize
    return ShareUtils.getPinterestShareUrl(
      this.state.refLink,
      shareImage,
      `Join me on ${_.get(settings, 'website.title')}! ${this.state.refLink}`,
    )
  }

  getTwitterShareUrl() {
    const tweetText = `Join me on ${_.get(settings, 'website.title')}!`
    return ShareUtils.getTwitterShareUrl(
      this.state.refLink,
      tweetText,
      _.get(settings, 'website.title'),
    )
  }

  getFbShareUrl() {
    return ShareUtils.getFbShareUrl(this.state.refLink)
  }

  getGooglePlusShareUrl() {
    return ShareUtils.getGooglePlusShareUrl(this.state.refLink)
  }

  getLinkedinShareUrl() {
    const title = `${_.get(settings, 'website.title')} - Awesome Apps by TSL`
    const summary = `Join me on ${_.get(settings, 'website.title')}!`
    return ShareUtils.getLinkedinShareUrl(this.state.refLink, title, summary, this.url)
  }

  getTumblrShareUrl() {
    const title = `${_.get(settings, 'website.title')} - Awesome Apps by TSL`
    const shareImage = this.props.profile.avatar.fullSize
    return ShareUtils.getTumblrShareUrl(
      this.state.refLink,
      title,
      _.get(settings, 'website.title'),
      shareImage,
      `Join me on ${_.get(settings, 'website.title')}! ${this.state.refLink}`,
    )
  }

  getEmailShareUrl() {
    let {email} = this.state
    email = email || ''
    return (
      'mailto:' +
      email +
      '?subject=' +
      this.props.profile.firstName +
      ' ' +
      this.props.profile.lastName +
      ` has invited you to join ${_.get(
        settings,
        'website.title',
      )}!&body=Use my referral link to signup: ` +
      this.state.refLink
    )
  }
}

function mapStateToProps(state) {
  return {...state.profile}
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch)
}

ProfileInfo.displayName = 'ProfileInfo'

ProfileInfo.propTypes = {
  onSave: PropTypes.func,
  validateOnBlur: PropTypes.bool,
}

ProfileInfo.contextTypes = {
  router: PropTypes.object.isRequired,
}

ProfileInfo.defaultProps = {
  validateOnBlur: true,
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withStyles(styles),
  AuthenticatedPage,
  ValidatedComponent,
)(ProfileInfo)
