import settings from '../../../config/settings'
import axios from '../api/axios'

const {apiBaseURL} = settings

export default class CoursesApi {
  static openCourse(courseId) {
    return axios.get(`${apiBaseURL}/courses/${courseId}/open/?expand=items.choices.options`)
  }

  static getCourse(courseId) {
    return axios.get(`${apiBaseURL}/courses/${courseId}/?expand=items.choices.options`)
  }

  static getTask(taskTypeId) {
    return axios.get(`${apiBaseURL}/tasks/?type=${taskTypeId}`)
  }

  static getTaskMe() {
    return axios.get(`${apiBaseURL}/tasks/me?expand=type.course.items.choices.options`)
  }

  static startCourseItem(itemId) {
    return axios.post(`${apiBaseURL}/course-items/${itemId}/start/`)
  }

  static completeCourseItem(itemId, answer) {
    return axios.post(`${apiBaseURL}/course-items/${itemId}/item-complete/`, {answer})
  }

  static completeCourse(courseId, payload) {
    return axios.post(`${apiBaseURL}/courses/${courseId}/complete/`, payload)
  }

  static resetCourse(courseId) {
    return axios.post(`${apiBaseURL}/courses/${courseId}/reset/`)
  }
}
