import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import {
  Card,
  CardContent,
  CardActions,
  Checkbox,
  FormControlLabel,
  FormLabel,
  Typography,
  Input,
  Grid,
  Button,
  withStyles,
} from '@material-ui/core'
import Link from 'react-router'
import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'
import qs from 'qs'
import autobind from 'autobind-decorator'
import settings from '../../../config/settings'
import InputValidator from '../common/InputValidator.jsx'
import ValidatedComponent from '../common/ValidatedComponent.jsx'
import {createProfile} from '../profile/redux/actions'
import * as Actions from './redux/actions'
import {FacebookAuthButton} from './SocialAuthButtons.jsx' //TwitterAuthButton
import {phoneRegex, imageFileRegex} from '../constants/Regex'
import styles from '../common/styles/CardLayout.styles'

class Signup extends React.Component {
  constructor() {
    super()

    this.state = {
      company: '',
      email: '',
      avatar: null,
      password: '',
      confirmPassword: '',
      firstName: '',
      lastName: '',
      phoneNumber: '',
      referralCode: '',
      acceptTerms: _.get(settings, 'website.automaticAccountAgreement'),
      acceptConsent: false,
      inputState: null,
    }

    this.passwordRef = React.createRef()
    this.confirmPasswordRef = React.createRef()
  }

  componentDidMount() {
    this.getReferralCode()
  }

  getReferralCode() {
    const {referral_code} = qs.parse(document.location.search) // eslint-disable-line camelcase
    this.setState({referralCode: referral_code})
  }

  @autobind
  createUser(event) {
    event.preventDefault()
    const {isComponentValid, createProfile} = this.props
    const {
      company,
      firstName,
      lastName,
      email,
      phoneNumber,
      password,
      avatar,
      referralCode,
    } = this.state

    if (isComponentValid()) {
      createProfile({
        company,
        firstName,
        lastName,
        email,
        phoneNumber,
        password,
        avatar,
        referralCode,
      })
    }
  }

  onChange(fieldName, e) {
    this.setState({[fieldName]: e.target.value})
  }

  onProfileImageUpload(e) {
    const avatar = e.target.files.item(0)
    if (!avatar || !imageFileRegex.test(avatar.type)) {
      return
    }

    const reader = new FileReader()
    reader.onload = e => {
      this.setState({
        avatar: e.target.result,
      })
    }
    reader.readAsDataURL(avatar)
  }

  @autobind
  validateConfirmPassword() {
    const {password, confirmPassword} = this.state
    if (password && confirmPassword && password !== confirmPassword) {
      return 'Both passwords must be equal'
    }
  }

  validatePhone() {
    const {phoneNumber} = this.state
    if (!phoneRegex.test(phoneNumber)) {
      return 'Please enter a valid phone number'
    }
  }

  render() {
    const {state} = this
    const {
      errors,
      // socialLoginOAuth1Step1,
      // socialLoginOAuth1Step2,
      socialLoginOAuth2,
      provider,
      // oauthToken,
      // oauthTokenSecret,
      isNoEmailProvidedError,
      classes,
    } = this.props
    return (
      <div className={classes.page}>
        <Helmet title="Sign up" />
        <Card className={classes.card}>
          <CardContent className={classes.cardContent}>
            <Typography component="h2" variant="h2" className={classes.title} gutterBottom>
              Register for an Account
            </Typography>
            <form noValidate>
              <Grid container spacing={32}>
                <Grid item xs={12} className={classes.infoField}>
                  <InputValidator fieldName="Company" shouldValidateOnBlur={true}>
                    <Input
                      type="text"
                      name="company"
                      placeholder="Company"
                      value={state.company}
                      onChange={this.onChange.bind(this, 'company')}
                    />
                  </InputValidator>
                </Grid>
                <Grid item xs={12} className={classes.infoField}>
                  <InputValidator fieldName="First Name" shouldValidateOnBlur={true}>
                    <Input
                      type="text"
                      name="firstName"
                      placeholder="First Name"
                      value={state.firstName}
                      onChange={this.onChange.bind(this, 'firstName')}
                    />
                  </InputValidator>
                </Grid>

                <Grid item xs={12} className={classes.infoField}>
                  <InputValidator fieldName="Last Name" shouldValidateOnBlur={true}>
                    <Input
                      type="text"
                      name="lastName"
                      placeholder="Last Name"
                      value={state.lastName}
                      onChange={this.onChange.bind(this, 'lastName')}
                    />
                  </InputValidator>
                </Grid>

                <Grid item xs={12} className={classes.infoField}>
                  <InputValidator
                    fieldName="Email"
                    errors={errors.email}
                    shouldValidateOnBlur={true}
                  >
                    <Input
                      type="email"
                      name="email"
                      placeholder="Email*"
                      required
                      onChange={this.onChange.bind(this, 'email')}
                      value={state.email}
                    />
                  </InputValidator>
                </Grid>

                <Grid item xs={12} className={classes.infoField}>
                  <InputValidator
                    fieldName="Phone"
                    errors={errors.phoneNumber}
                    shouldValidateOnBlur={true}
                    customValidation={() => {
                      return this.validatePhone()
                    }}
                  >
                    <Input
                      type="tel"
                      name="phone"
                      placeholder="Phone*"
                      required
                      onChange={this.onChange.bind(this, 'phoneNumber')}
                      value={state.phoneNumber}
                    />
                  </InputValidator>
                </Grid>

                <Grid item xs={12} className={classes.infoField}>
                  <InputValidator
                    forwardRef={this.passwordRef}
                    fieldName="Password"
                    errors={errors.password}
                    shouldValidateOnBlur={true}
                    onBlurValidators={this.confirmPasswordRef && [this.confirmPasswordRef.current]}
                    customValidation={() => {
                      return this.validateConfirmPassword()
                    }}
                    className={this.state.inputState}
                  >
                    <Input
                      type="password"
                      name="password"
                      placeholder="Password*"
                      required
                      onChange={this.onChange.bind(this, 'password')}
                      value={state.password}
                    />
                  </InputValidator>
                </Grid>

                <Grid item xs={12} className={classes.infoField}>
                  <InputValidator
                    forwardRef={this.confirmPasswordRef}
                    fieldName="Confirm Password"
                    shouldValidateOnBlur={true}
                    onBlurValidators={this.passwordRef && [this.passwordRef.current]}
                    customValidation={() => {
                      return this.validateConfirmPassword()
                    }}
                    className={this.state.inputState}
                  >
                    <Input
                      type="password"
                      name="password-confirm"
                      placeholder="Confirm Password*"
                      required
                      onChange={this.onChange.bind(this, 'confirmPassword')}
                      value={state.confirmPassword}
                    />
                  </InputValidator>
                </Grid>

                <Grid item xs={12} className={classes.infoField}>
                  <FormLabel htmlFor="profile-image">Profile Image</FormLabel>
                  <InputValidator
                    canShowLabel={false}
                    errors={errors.profileImage}
                    shouldValidateOnBlur={false}
                  >
                    <Input
                      type="file"
                      name="profile-image"
                      accept="image/*"
                      onChange={this.onProfileImageUpload.bind(this)}
                    />
                  </InputValidator>
                </Grid>

                {!_.get(settings, 'website.automaticAccountAgreement') ? (
                  <Grid item xs={12} className={classes.infoField}>
                    <FormLabel>Terms and Conditions</FormLabel>
                    <InputValidator canShowLabel={false} errors={errors.acceptTerms}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            id="terms"
                            name="terms-and-conditions"
                            checked={state.acceptTerms}
                            onChange={e => this.setState({acceptTerms: e.target.checked})}
                            value="accept_terms"
                          />
                        }
                        label={
                          <span>
                            I agree to the{' '}
                            <a href="/equity-terms" target="_blank" rel="noopener noreferrer">
                              Terms of Use
                            </a>
                            ,{' '}
                            <a href="/equity-privacy" target="_blank" rel="noopener noreferrer">
                              Privacy Policy
                            </a>{' '}
                            and{' '}
                            <a href="/compensation" target="_blank" rel="noopener noreferrer">
                              Compensation Disclaimer
                            </a>
                            .
                          </span>
                        }
                      />
                    </InputValidator>
                  </Grid>
                ) : null}

                <Grid item xs={12} className={classes.infoField}>
                  <FormLabel>Electronic Materials Consent</FormLabel>
                  <InputValidator errors={errors.acceptConsent}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          id="consent"
                          name="consent"
                          value="accept_consent"
                          checked={state.acceptConsent}
                          onChange={e => this.setState({acceptConsent: e.target.checked})}
                        />
                      }
                      label={`I consent to the delivery of electronic materials relating to business conducted
                          through ${_.get(settings, 'website.title')}`}
                    />
                  </InputValidator>
                </Grid>

                <Grid item xs={12} className={classes.infoField}>
                  <Button
                    type="submit"
                    name="btn-create"
                    fullWidth
                    variant="contained"
                    onClick={this.createUser}
                  >
                    Register
                  </Button>
                </Grid>

                {_.get(settings, 'website.socialLogins') ? (
                  <Grid item xs={12} className={classes.infoField}>
                    <Typography
                      component="h3"
                      variant="subtitle1"
                      className={classes.titleNoMar}
                      gutterBottom
                    >
                      Or create one with an existing social account
                    </Typography>
                    <Grid container justify="center" spacing={16}>
                      <Grid item xs="auto" className={classes.title}>
                        <FacebookAuthButton
                          fbAppId={settings.fbAppId}
                          onSendAuthRequest={socialLoginOAuth2.bind(this)}
                          isNoEmailProvidedError={isNoEmailProvidedError && provider === 'facebook'}
                        />
                      </Grid>
                      {/* <Grid item xs="auto">
                        <TwitterAuthButton
                          oauthToken={oauthToken}
                          oauthTokenSecret={oauthTokenSecret}
                          onRetrieveAuthRequestToken={socialLoginOAuth1Step1.bind(this)}
                          onSendAuthRequest={socialLoginOAuth1Step2.bind(this)}
                          isNoEmailProvidedError={isNoEmailProvidedError && provider === 'twitter'}
                        />
                      </Grid> */}
                    </Grid>
                  </Grid>
                ) : null}
                {_.get(settings, 'website.automaticAccountAgreement') ? (
                  <Grid item xs={12}>
                    <Typography
                      component="p"
                      variant="caption"
                      className={classes.titleNoMar}
                      gutterBottom
                    >
                      By signing up, you agree to the{' '}
                      <a href="/equity-terms" target="_blank" rel="noopener noreferrer">
                        Terms of Use
                      </a>
                      ,{' '}
                      <a href="/equity-privacy" target="_blank" rel="noopener noreferrer">
                        Privacy Policy
                      </a>{' '}
                      and{' '}
                      <a href="/compensation" target="_blank" rel="noopener noreferrer">
                        Compensation Disclaimer
                      </a>
                      .
                    </Typography>
                  </Grid>
                ) : null}
              </Grid>
            </form>
          </CardContent>
          <CardActions className={classes.cardActions}>
            <Button component={Link} to="/help">
              Need help?
            </Button>
          </CardActions>
        </Card>
        <div className="push" />
      </div>
    )
  }
}

Signup.displayName = 'Signup'

Signup.contextTypes = {
  router: PropTypes.object.isRequired,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({...Actions, createProfile}, dispatch)
}

function mapStateToProps(state) {
  const {oauthToken, oauthTokenSecret, isNoEmailProvidedError, provider} = state.auth
  return {
    ...state.signup,
    oauthToken,
    oauthTokenSecret,
    isNoEmailProvidedError,
    provider,
  }
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withStyles(styles),
  ValidatedComponent,
)(Signup)
