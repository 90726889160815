import React from 'react'
import PropTypes from 'prop-types'
import {Grid, TextField, Button, Typography} from '@material-ui/core'
import InputValidator from '../common/InputValidator.jsx'
import ValidatedComponent from '../common/ValidatedComponent.jsx'

class ChangeEmail extends React.Component {
  constructor() {
    super()

    this.state = {
      newEmail: '',
    }
  }

  render() {
    const {newEmail} = this.state
    const {email, errors, savedNewEmail, isNewEmailConfirmed} = this.props

    const isConfirmationPending = !!savedNewEmail && !isNewEmailConfirmed
    const isVerificationPending = !!savedNewEmail && isNewEmailConfirmed
    const isChangeRequested = !!savedNewEmail

    return (
      <React.Fragment>
        <Grid item xs={12}>
          <Typography component="h3" variant="h3" gutterBottom>
            Change Account Email
          </Typography>
        </Grid>

        {!isChangeRequested ? (
          <Grid item xs={12}>
            <InputValidator errors={errors.newEmail}>
              <TextField
                type="email"
                value={newEmail}
                required
                placeholder="New email"
                onChange={e => this.setState({newEmail: e.target.value})}
              />
            </InputValidator>
          </Grid>
        ) : null}
        <Grid item xs={12}>
          <br />
          {!isChangeRequested ? (
            <Button onClick={this.onChangeEmailClick.bind(this)} variant="contained">
              Change email
            </Button>
          ) : null}
          {isConfirmationPending ? (
            <div>
              <p>
                You requested to change email to <b>{savedNewEmail}</b>. Please continue with the
                confirmation email sent to <b>{email}</b>.
              </p>
              <Button onClick={this.props.onResendConfirmationEmail} variant="contained">
                Resend confirmation email
              </Button>
            </div>
          ) : null}
          {isVerificationPending ? (
            <div>
              <p>
                A verification email has been sent to the new email address (<b>{savedNewEmail}</b>
                ).
              </p>
              <Button onClick={this.props.onResendVerificationEmail} variant="contained">
                Resend verification email
              </Button>
            </div>
          ) : null}
          {isChangeRequested ? (
            <div>
              <p>
                Your current email is <b>{email}</b>
              </p>
              <Button onClick={this.props.onCancelChangeEmail} variant="contained">
                Cancel change request
              </Button>
            </div>
          ) : null}
        </Grid>
      </React.Fragment>
    )
  }

  onChangeEmailClick() {
    const {isComponentValid, onChangeEmail} = this.props
    if (isComponentValid()) {
      onChangeEmail(this.state.newEmail)
    }
  }
}

ChangeEmail.displayName = 'ChangeEmail'
ChangeEmail.propTypes = {
  errors: PropTypes.object,
  email: PropTypes.string.isRequired,
  savedNewEmail: PropTypes.string,
  isNewEmailConfirmed: PropTypes.bool,
  onChangeEmail: PropTypes.func.isRequired,
  onCancelChangeEmail: PropTypes.func.isRequired,
  onResendConfirmationEmail: PropTypes.func.isRequired,
  onResendVerificationEmail: PropTypes.func.isRequired,
}

export default ValidatedComponent(ChangeEmail)
