export default theme => ({
  root: {
    width: '100%',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    padding: '20px 110px',

    [theme.breakpoints.down('sm')]: {
      padding: '20px',
    },

    '& h2': {
      color: '#69788C',
      fontWeight: 'bold',
      fontSize: '18px',
      margin: 0,
      marginBottom: '10px',
    },
  },

  mainCont: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
})
