import React, {useState, useEffect} from 'react'
import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import {withStyles} from '@material-ui/styles'
import {Paper, Button, Grid} from '@material-ui/core'
import cx from 'classnames'
import _ from 'lodash'
import * as Actions from './redux/actions'
import * as HeaderActions from '../common/redux/actions.header'
import * as NotificationActions from '../common/redux/actions.notifications'
import styles, {ChoiceTypography} from './styles/CourseItem.trueFalse.styles.js'

function CourseItemTrueFalse({
  classes,
  item,
  hasMinDurationPassed,
  onCourseItemComplete,
  isCompletingItem,
  router,
}) {
  const [currentIndex, setCurrentIndex] = useState(0)
  const [boolean, setBoolean] = useState(null)
  const questions = item?.boolean || []
  const currentQuestion = questions[currentIndex]

  useEffect(() => {
    setBoolean(null)
    setCurrentIndex(0)
  }, [router.location.pathname])

  const handleTrue = () => setBoolean(true)
  const handleFalse = () => setBoolean(false)

  const handleNext = () => {
    if (currentIndex < questions.length - 1) {
      setCurrentIndex(currentIndex + 1)
    } else {
      onCourseItemComplete(item, null)
    }

    setBoolean(null)
  }

  return (
    <div className={classes.root}>
      <div className={classes.mainCont}>
        <Paper className={classes.textCont}>
          {item?.image && (
            <img className={classes.textImage} src={item?.image?.fullSize} alt="text" />
          )}
          <ChoiceTypography>{currentQuestion?.question || 'No more questions'}</ChoiceTypography>
        </Paper>
        <Grid container className={classes.ButtonCont} spacing={12}>
          <Grid item xs={false} sm={1} />

          <Button
            onClick={handleTrue}
            className={cx(classes.button, boolean === true ? classes.selected : null)}
          >
            True
          </Button>

          <Button
            onClick={handleFalse}
            className={cx(
              classes.falseButton,
              classes.button,
              boolean === false ? classes.selected : null,
            )}
          >
            False
          </Button>

          <Grid item xs={false} sm={1} />
          <Grid item xs={12}>
            <Button
              className={cx(
                classes.button,
                classes.nextButton,
                !hasMinDurationPassed || isCompletingItem ? classes.buttonDisabled : '',
              )}
              disabled={!hasMinDurationPassed || isCompletingItem}
              onClick={handleNext}
            >
              Next
            </Button>
          </Grid>
        </Grid>
      </div>
    </div>
  )
}

CourseItemTrueFalse.displayName = 'CourseItemTrueFalse'

function mapStateToProps(state) {
  return {
    courses: _.get(state, 'course'),
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({...Actions, ...HeaderActions, ...NotificationActions}, dispatch)
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withStyles(styles, {withTheme: true}),
)(CourseItemTrueFalse)
