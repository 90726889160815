import React from 'react'
import ReactDOM from 'react-dom'
import {browserHistory} from 'react-router'
import {applyMiddleware, createStore} from 'redux'
import thunkMiddleware from 'redux-thunk'
import createSagaMiddleware from 'redux-saga'
import {createLogger} from 'redux-logger'
import {routerMiddleware, syncHistoryWithStore} from 'react-router-redux'
import createRavenMiddleware from 'raven-for-redux'
import reduxCatch from 'redux-catch'
import Raven from 'raven-js'
import UserSession from './shared/auth/UserSession'
import sagas from './shared/sagas'
import reducers from './shared/reducers'
import settings from '../config/settings'
import theme from './shared/theme'
import './styles/main.scss'
import Root from './Root.jsx'
import {setToken} from './shared/auth/redux/actions'
import {getPathParam, removeURLParameter} from './shared/utils/PathUtils'
// Export React so the dev tools can find it
if (window === window.top) {
  window.React = React
}

UserSession.setClientAuthCookie()

const reduxLoggerMiddleware = createLogger()
const sagaMiddleware = createSagaMiddleware()

let initialState = null
if (window.__STATE__) {
  initialState = window.__STATE__
}

// create a store with middlewares
const reduxMiddleware = [thunkMiddleware, sagaMiddleware, routerMiddleware(browserHistory)]

if (__DEVCLIENT__) {
  //turn off redux logs in production
  reduxMiddleware.push(reduxLoggerMiddleware)
}
if (settings.sentry && settings.sentry.publicDSN) {
  const {sentry} = settings
  Raven.config(sentry.publicDSN, {
    release: sentry.release,
    environment: sentry.environment,
  }).install()
  reduxMiddleware.unshift(createRavenMiddleware(Raven))
  reduxMiddleware.push(reduxCatch(error => Raven.captureException(error)))
}

const store = createStore(reducers, initialState, applyMiddleware(...reduxMiddleware))
const urlToken = getPathParam('token')

if (urlToken) {
  store.dispatch(setToken(urlToken))
  UserSession.setToken(urlToken)
  window.location.href = removeURLParameter(window.location.href, 'token')
}

const history = syncHistoryWithStore(browserHistory, store)
sagaMiddleware.run(sagas)

const renderApp = () => {
  ReactDOM.hydrate(
    <Root store={store} history={history} theme={theme} />,
    document.getElementById('app'),
  )
}

renderApp()

if (module.hot) {
  module.hot.accept('./Root.jsx', () => renderApp())
}
