const styles = theme => ({
  page: {
    padding: '30px 0 0 0',
  },
  title: {
    textAlign: 'center',
    margin: '0 0 40px 0',
  },
  titleNoMar: {
    textAlign: 'center',
  },
  card: {
    maxWidth: '700px',
    margin: '0 auto',
  },
  cardCentered: {
    maxWidth: '700px',
    margin: '0 auto',
    justifyContent: 'center',
    textAlign: 'center',
    marginBottom: '24px',
  },
  cardActions: {
    marginTop: '-50px',
    borderTop: '1px solid #ccc',
    justifyContent: 'center',
  },
  cardContent: {
    padding: '42px 25px',
    marginBottom: '24px',
  },
  confirmButton: {
    marginTop: '20px',
  },
  infoField: {
    marginTop: theme.spacing(2),
  },
})

export default styles
