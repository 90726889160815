import {combineReducers} from 'redux'
import {routerReducer} from 'react-router-redux'
import responseStatus from './common/redux/reducers.responseStatus'
import auth from './auth/redux/reducers'
import forgotPw from './auth/redux/reducers.forgotPw'
import profile from './profile/redux/reducers'
import notifications from './common/redux/reducers.notifications'
import signup from './auth/redux/reducers.signup'
import header from './common/redux/reducers.header'
import course from './courses/redux/reducers'

const rootReducer = combineReducers({
  routing: routerReducer,
  responseStatus,
  auth,
  forgotPw,
  profile,
  notifications,
  signup,
  header,
  course,
})

export default rootReducer
