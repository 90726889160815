export default theme => ({
  root: {
    width: '100%',
    display: 'flex',
    flex: 1,
    background: '#EDEDED',
    flexDirection: 'column',
  },

  mainCont: {
    width: '100%',
    padding: '20px 25%',
    [theme.breakpoints.down('sm')]: {
      padding: '20px 15%',
    },
  },
  iconCont: {textAlign: 'center'},
  mainHeader: {
    color: '#AFAFAF',
    fontFamily: 'Lato',
    fontSize: '34px',
    fontWeight: 'bold',
    letterSpacing: 0,
    lineHeight: '39px',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      fontSize: '20px',
    },
  },
  mainContent: {
    marginTop: '7px',
    color: '#AFAFAF',
    fontFamily: 'Lato',
    fontSize: '23px',
    letterSpacing: 0,
    lineHeight: '26px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
    },
  },
  signatureIcon: {
    width: '200px',
    [theme.breakpoints.down('sm')]: {
      width: '150px',
    },
  },
  canvasButtonContainer: {
    display: 'flex',
    marginTop: 20,
    justifyContent: 'center',
  },
  signatureWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    marginTop: 30,
  },
  signatureContainer: {
    position: 'relative',
  },
  canvas: {
    width: 500,
    height: 150,
    border: '1px solid rgba(0, 0, 0, 0.54)',
    [theme.breakpoints.down('sm')]: {
      width: 275,
      height: 100,
    },
  },
  signatureText: {
    margin: 0,
    position: 'absolute',
  },
  clearSignatureButton: {
    width: 200,
  },
  signatureDisabled: {
    opacity: 0.5,
  },
  button: {
    [theme.breakpoints.down('sm')]: {
      top: '20px',
      bottom: '40px',
      paddingLeft: '40px',
      paddingRight: '40px',
    },
    textAlign: 'center',
    paddingLeft: '80px',
    paddingRight: '80px',
    background: '#19AF5B',
    borderRadius: '21.5px',
    height: '43px',
    width: '240px',
    color: 'white',

    '&:hover': {
      backgroundColor: '#15934c',
    },
  },
  buttonCont: {
    marginTop: 40,
    textAlign: 'center',
    marginBottom: '10px',
    [theme.breakpoints.down('sm')]: {
      marginTop: 10,
    },
  },
  buttonDisabled: {
    background: '#D3D3D3',
    '&:hover': {
      backgroundColor: '#D3D3D3',
    },
  },
})
