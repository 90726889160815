/* eslint-disable react/jsx-filename-extension */
import React from 'react'
import {IndexRoute, Route} from 'react-router'
import {authenticateOnEnter} from './utils/RoutingUtils'
import App from './App.jsx'
import NotFound from './common/NotFound.Page.jsx'
import Home from './home/Home.jsx'
import About from './about/About.jsx'
import Login from './auth/Login.jsx'
import Signup from './auth/Signup.jsx'
import ForgotPasswordRequest from './auth/ForgotPasswordRequest.jsx'
import ForgotPasswordConsume from './auth/ForgotPasswordConsume.jsx'
import Profile from './profile/Profile.jsx'
import ProfileInfo from './profile/ProfileInfo.jsx'
import ProfileSecurity from './profile/ProfileSecurity.jsx'
import ConfirmEmail from './profile/ConfirmEmail.jsx'
import ChangeEmailConfirm from './profile/ChangeEmailConfirm.jsx'
import ChangeEmailVerify from './profile/ChangeEmailVerify.jsx'
import StyleGuide from './styleguide/styleguide.jsx'
import CourseDetail from './courses/CourseDetail.index'
import CourseItem from './courses/CourseItem.index'
import CourseSignature from './courses/CourseSignature'

import CourseSummary from './courses/CourseSummary.index'

export default function routing(store) {
  const authenticate = authenticateOnEnter.bind(this, store)

  return (
    <Route path="/" component={App}>
      {/*Auth*/}
      <Route path="/about" component={About} />
      <Route path="/styleguide" component={StyleGuide} />
      <Route path="/login" component={Login} />
      <Route path="/signup" component={Signup} />
      <Route path="/forgot" component={ForgotPasswordRequest} />
      <Route path="/forgot-password/:token" component={ForgotPasswordConsume} />
      <Route path="/profile" component={Profile} onEnter={authenticate}>
        <IndexRoute component={ProfileInfo} />
        <Route path="security" component={ProfileSecurity} />
      </Route>
      <Route path="/confirm-email/:id/:token" component={ConfirmEmail} />
      <Route path="/change-email/:id/:token" component={ChangeEmailConfirm} />
      <Route path="/change-email-verify/:id/:token" component={ChangeEmailVerify} />
      {/*Trainings*/}
      <IndexRoute component={Home} onEnter={authenticate} />
      <Route path="/course/:id" component={CourseDetail} onEnter={authenticate} />
      <Route path="/course/:id/item/:id" component={CourseItem} onEnter={authenticate} />
      <Route path="/course/:id/signature" component={CourseSignature} onEnter={authenticate} />
      <Route path="/course/:id/summary" component={CourseSummary} onEnter={authenticate} />
      {/* should always be at the end */}
      <Route path="*" component={NotFound} />
    </Route>
  )
}
