export default theme => ({
  root: {
    width: '100%',
    display: 'flex',
    flex: 1,
    background: '#EDEDED',
    flexDirection: 'column',
  },

  mainCont: {
    width: '100%',
    padding: '20px 25%',
    [theme.breakpoints.down('sm')]: {
      padding: '20px 15%',
    },
  },
  iconCont: {textAlign: 'center'},
  mainHeader: {
    color: '#AFAFAF',
    fontSize: '34px',
    fontWeight: 'bold',
    letterSpacing: 0,
    lineHeight: '39px',
    textAlign: 'center',
  },
  mainContent: {
    marginTop: '7px',
    color: '#AFAFAF',
    fontSize: '24px',
    fontWeight: 'bold',
    textAlign: 'center',
    letterSpacing: 0,
    lineHeight: '26px',
  },

  image: {
    width: '200px',
  },

  completedDate: {
    color: '#AFAFAF',
    fontSize: '20px',
    marginTop: '30px',
    marginBottom: '30px',
    textAlign: 'center',
  },

  doneButton: {
    paddingLeft: '80px',
    paddingRight: '80px',
    background: '#19AF5B',
    borderRadius: '21.5px',
    height: '43px',
    color: 'white',
    marginTop: '30px',

    '&:hover': {
      opacity: 0.8,
    },
  },
})
