import React from 'react'
import LinearProgress from '@material-ui/core/LinearProgress'
import {withStyles} from '@material-ui/core/styles'

function TimerLinearProgress(props) {
  const {classes} = props
  return (
    <LinearProgress
      {...props}
      classes={{
        colorPrimary: classes.colorPrimary,
        barColorPrimary: classes.barColorPrimary,
        root: classes.root,
      }}
    />
  )
}

const styles = () => ({
  colorPrimary: {
    backgroundColor: '#D8D8D8',
  },
  barColorPrimary: {
    backgroundColor: '#1973E8',
  },
  root: {
    borderRadius: '5.5px',
    height: 11,
  },
})

export default withStyles(styles)(TimerLinearProgress)
