import React, {useEffect, useState} from 'react'
import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import {withStyles} from '@material-ui/styles'
import _ from 'lodash'

import {Typography, Grid, Button} from '@material-ui/core'
import cx from 'classnames'
import styles from './styles/CourseSignature.styles.js'
import * as HeaderActions from '../common/redux/actions.header'
import * as Actions from './redux/actions'
import AuthenticatedPage from '../common/AuthenticatedPage.jsx'
import AlfLinearProgress from '../common/AlfLinearProgress'
import {Icons} from './Utils'
import Signature, {getImageFromCanvasRef} from '../common/Signature'

import {createNotificationFromError, createSuccess} from '../common/redux/actions.notifications'

function CourseSignature({classes, setHeader, params, getCourse, router, courses}) {
  const signature = React.createRef()
  const [noSignature, setNoSignature] = useState(true)
  useEffect(() => {
    setHeader({hidden: false})
    getCourse(_.get(params, 'id'))
  }, [])

  const {isFetching, course} = courses
  async function goToNextSection() {
    const data = {}
    data.signature = getImageFromCanvasRef(signature)
    router.push({pathname: `/course/${_.get(params, 'id')}/summary`, state: data})
  }

  const renderSignature = () => {
    return (
      <div className={classes.signatureWrapper}>
        <Signature
          canvasRef={signature}
          setNoSignature={setNoSignature}
          classes={{
            canvas: classes.canvas,
            buttonContainer: classes.canvasButtonContainer,
            clearButton: classes.clearSignatureButton,
            signatureText: classes.signatureText,
            signatureContainer: classes.signatureContainer,
            signatureDisabled: classes.signatureDisabled,
          }}
        />
      </div>
    )
  }

  return (
    <Grid container className={classes.root}>
      {isFetching && <AlfLinearProgress />}
      {!_.isEmpty(course) && (
        <Grid container className={classes.mainCont}>
          <Grid item xs={12} className={classes.iconCont}>
            <img className={classes.signatureIcon} src={Icons.signatureIcon} alt="Signature Icon" />
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.mainHeader}>You Passed!</Typography>
            <Typography className={classes.mainContent}>
              AHCA requires you acknowledge that you and your Administrator have reviewed all
              facility- specific polices and procedures relating to this training. Your
              Administrator will confirm review of policies and procedures before s/he signs. Please
              sign below.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {renderSignature()}
          </Grid>
          <Grid item xs={12} className={classes.buttonCont}>
            <Button
              onClick={goToNextSection}
              className={!noSignature ? classes.button : cx(classes.button, classes.buttonDisabled)}
              disabled={noSignature}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      )}
    </Grid>
  )
}

CourseSignature.displayName = 'CourseSignature'

function mapStateToProps(state) {
  return {
    courses: _.get(state, 'course'),
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...Actions,
      ...HeaderActions,
      createNotificationFromError,
      createSuccess,
    },
    dispatch,
  )
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withStyles(styles, {withTheme: true}),
  AuthenticatedPage,
)(CourseSignature)
