import React, {useState} from 'react'
import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import {withStyles} from '@material-ui/styles'
import {Paper, Button} from '@material-ui/core'
import _ from 'lodash'
import Linkify from 'react-linkify'
import * as Actions from './redux/actions'
import * as HeaderActions from '../common/redux/actions.header'
import styles from './styles/CourseItem.text.styles.js'

function CourseItemText({
  classes,
  item,
  hasMinDurationPassed,
  onCourseItemComplete,
  isCompletingItem,
}) {
  const [currentTextIndex, setCurrentTextIndex] = useState(0)

  const textItems = item?.texts || []
  const currentTextItem = textItems[currentTextIndex]

  const handleNextClick = () => {
    if (currentTextIndex < textItems.length - 1) {
      setCurrentTextIndex(prevIndex => prevIndex + 1)
    } else {
      onCourseItemComplete(item, null)
    }
  }

  return (
    <div className={classes.root}>
      <div className={classes.mainCont}>
        <Paper className={classes.textCont}>
          {item?.image && (
            <img className={classes.textImage} src={item?.image?.fullSize} alt="text" />
          )}
          <Linkify>{currentTextItem?.question.replaceAll('*', '●') || ''}</Linkify>
        </Paper>
        <div className={hasMinDurationPassed ? classes.nextButton : classes.nextButtonDisabled}>
          <Button
            disabled={!hasMinDurationPassed || isCompletingItem || !currentTextItem}
            onClick={handleNextClick}
          >
            Next
          </Button>
        </div>
      </div>
    </div>
  )
}

CourseItemText.displayName = 'CourseItemText'

function mapStateToProps(state) {
  return {
    courses: _.get(state, 'course'),
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({...Actions, ...HeaderActions}, dispatch)
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withStyles(styles, {withTheme: true}),
)(CourseItemText)
