import React from 'react'
import {Link} from 'react-router'
import _ from 'lodash'
import Grid from '@material-ui/core/Grid'
import {Typography, Paper, IconButton} from '@material-ui/core'
import {withStyles} from '@material-ui/styles'
import settings from '../../../config/settings'
import {Logo} from './Icons.jsx'

const styles = theme => ({
  paper: {
    padding: theme.spacing(4),
    marginRight: '20px',
    marginLeft: '20px',
    background: theme.palette.primary.main,
    textAlign: 'center',

    [theme.breakpoints.down('xs')]: {
      margin: 0,
    },
  },
  logo: {
    width: '55px',
    height: '55px',
  },
  footerLogoContainer: {
    textAlign: 'center',
    margin: theme.spacing(6, 0, 3),
  },
  footerLinks: {
    marginTop: theme.spacing(3),
  },
  copyright: {
    color: theme.palette.common.white,
  },
})

class Footer extends React.Component {
  backToTop(e) {
    e.preventDefault()

    const cosParameter = window.scrollY / 2

    let scrollCount = 0

    let oldTimestamp = performance.now()

    function step(newTimestamp) {
      scrollCount += Math.PI / (1000 / (newTimestamp - oldTimestamp))
      if (scrollCount >= Math.PI) window.scrollTo(0, 0)
      if (window.scrollY === 0) return
      window.scrollTo(0, Math.round(cosParameter + cosParameter * Math.cos(scrollCount)))
      oldTimestamp = newTimestamp
      window.requestAnimationFrame(step)
    }

    window.requestAnimationFrame(step)
  }

  showCurrentYear() {
    return new Date().getFullYear()
  }

  render() {
    const {classes} = this.props

    return (
      <footer style={{width: '100%'}}>
        <Paper className={classes.paper} elevation={24}>
          <Grid container direction="row" justify="center">
            <Grid item md={12} lg={12} className={classes.footerLogoContainer}>
              <IconButton
                onClick={() => this.props.onHomeClick()}
                className={classes.navbarTitleLink}
                component={Link}
                to="/"
              >
                <Logo className={classes.logo} />
              </IconButton>
              <br />
              <Typography variant="caption" className={classes.copyright}>
                © {this.showCurrentYear()} {_.get(settings, 'website.title')}. All Rights Reserved.
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </footer>
    )
  }
}

Footer.displayName = 'Footer'

export default withStyles(styles)(Footer)
