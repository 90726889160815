export default theme => ({
  root: {
    width: '100%',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    position: 'relative',
    paddingBottom: '43px',
  },

  mainCont: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },

  wistiaCont: {
    width: '60vw',
    height: '60vh',
    border: '1px solid #D8D8D8',
    borderRadius: '5px',
    [theme.breakpoints.down('xs')]: {
      width: '80%',
      height: '40vh',
    },
  },

  nextButton: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'white',

    [theme.breakpoints.down('sm')]: {
      bottom: '40px',
    },

    '& button': {
      paddingLeft: '80px',
      paddingRight: '80px',
      background: '#19AF5B !important',
      borderRadius: '21.5px',
      height: '43px',
      color: 'white',

      '&:hover': {
        backgroundColor: '#15934c',
      },
    },
  },
  nextButtonDisabled: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'white',

    [theme.breakpoints.down('sm')]: {
      bottom: '40px',
    },

    '& button': {
      paddingLeft: '80px',
      paddingRight: '80px',
      background: '#D3D3D3',
      borderRadius: '21.5px',
      height: '43px',
      color: 'white',

      '&:hover': {
        backgroundColor: '#D3D3D3',
      },
    },
  },
})
