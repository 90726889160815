import * as HeaderActions from './actions.header.types'

export function setHeader(params) {
  return {
    type: HeaderActions.SET_HEADER,
    data: {
      visible: true,
      params,
    },
  }
}

export function hideHeader() {
  return {
    type: HeaderActions.HIDE_HEADER,
    data: {
      visible: false,
    },
  }
}
