import * as Types from './actions.types'

export function getCourse(courseId) {
  return {type: Types.COURSE_GET, courseId}
}

export function getCourseSuccess(profile) {
  return {type: Types.COURSE_GET_SUCCESS, payload: profile}
}

export function getCourseFailure(error) {
  return {type: Types.COURSE_GET_FAILURE, error}
}

export function setLastCompletedCourseItem(item) {
  return {type: Types.COURSE_SET_LAST_COMPLETED_ITEM, item}
}

export function startCourseItem(itemId) {
  return {type: Types.COURSE_ITEM_START, itemId}
}

export function startCourseItemSuccess() {
  return {type: Types.COURSE_ITEM_START_SUCCESS}
}

export function startCourseItemFailure() {
  return {type: Types.COURSE_ITEM_START_FAILURE}
}
