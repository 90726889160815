import React, {useEffect, useState} from 'react'
import {withStyles} from '@material-ui/styles'
import {Button} from '@material-ui/core'
import styles from './styles/CourseItem.video.styles.js'

function CourseItemVideo({
  classes,
  item,
  hasMinDurationPassed,
  onCourseItemComplete,
  isCompletingItem,
}) {
  const [currentIndex, setCurrentIndex] = useState(0)
  const videoItems = item?.videos || []
  const currentVideoItem = videoItems[currentIndex]

  useEffect(() => {
    loadWistiaScript()
  }, [])

  useEffect(() => {
    initializeWistiaEmbed()
  }, [currentVideoItem?.videoId])

  const loadWistiaScript = () => {
    if (!document.querySelector('script[src="//fast.wistia.com/assets/external/E-v1.js"]')) {
      const script = document.createElement('script')
      script.src = '//fast.wistia.com/assets/external/E-v1.js'
      script.async = true
      script.onload = initializeWistiaEmbed
      document.body.appendChild(script)
    } else {
      initializeWistiaEmbed()
    }
  }

  const initializeWistiaEmbed = () => {
    const container = document.querySelector('.wistia-container')
    if (container) {
      container.innerHTML = ''
      const div = document.createElement('div')
      div.className = `wistia_embed wistia_async_${currentVideoItem?.videoId} ${classes.wistiaCont}`
      container.appendChild(div)

      if (window.Wistia) {
        window.Wistia.api(div)
      }
    }
  }

  const handleNextClick = () => {
    if (currentIndex < videoItems.length - 1) {
      setCurrentIndex(prevIndex => prevIndex + 1)
    } else {
      onCourseItemComplete(item, null)
    }
  }

  return (
    <div className={classes.root}>
      <div className={classes.mainCont}>
        <div className="wistia-container" />
        <div className={hasMinDurationPassed ? classes.nextButton : classes.nextButtonDisabled}>
          <Button disabled={!hasMinDurationPassed || isCompletingItem} onClick={handleNextClick}>
            Next
          </Button>
        </div>
      </div>
    </div>
  )
}

export default withStyles(styles)(CourseItemVideo)
