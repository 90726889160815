import {withStyles} from '@material-ui/styles'
import {Typography} from '@material-ui/core'

export const styles = theme => ({
  root: {
    width: '100%',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    position: 'relative',
    paddingBottom: '43px',
  },

  textCont: {
    padding: '19px 19px 49px 33px ',
    border: '1px solid #D8D8D8',
    borderRadius: '5px',
  },

  mainCont: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },

  imageCont: {
    padding: '16px',
    wordBreak: 'break-word',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    [theme.breakpoints.down('sm')]: {
      padding: '5px',
    },
  },

  imageBox: {
    padding: '20px',
    minWidth: '600px',
    '& img': {
      objectFit: 'contain !important',
    },

    [theme.breakpoints.down('sm')]: {
      padding: '10px',
      minWidth: '80vw',
    },

    [theme.breakpoints.down('sm')]: {
      padding: '10px',
      minWidth: '80vw',

      '& img': {
        objectFit: 'inherit !important',
      },
    },
  },

  nextButton: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'white',

    [theme.breakpoints.down('sm')]: {
      bottom: '40px',
    },

    '& button': {
      paddingLeft: '80px',
      paddingRight: '80px',
      background: '#19AF5B !important',
      borderRadius: '21.5px',
      height: '43px',
      color: 'white',

      '&:hover': {
        backgroundColor: '#15934c',
      },
    },
  },

  nextButtonDisabled: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'white',

    [theme.breakpoints.down('sm')]: {
      bottom: '40px',
    },

    '& button': {
      paddingLeft: '80px',
      paddingRight: '80px',
      background: '#D3D3D3 !important',
      borderRadius: '21.5px',
      height: '43px',
      color: 'white',

      '&:hover': {
        backgroundColor: '#15934c',
      },
    },
  },
  radioRoot: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  radioIcon: {
    width: 43,
    color: '#69788C',
    height: 43,
    fontSize: '20px',
    fontWeight: '400',
    border: '1px solid #69788C',
    borderRadius: '21.5px',
    backgroundColor: '#FDFDFD',
  },
  radioCheckedIcon: {
    color: '#FDFDFD',

    backgroundColor: '#69788C',
  },
  choicesControl: {
    '& span': {
      color: '#69788C',
      fontFamily: 'Lato',
      fontSize: '18px',
      fontWeight: 'bold',
      letterSpacing: 0,
      lineHeight: '22px',
    },
  },
})

export const ChoiceTypography = withStyles({
  root: {
    color: '#69788C',
    fontFamily: 'Lato',
    fontSize: '18px',
    fontWeight: 'bold',
    letterSpacing: 0,
    lineHeight: '22px',
  },
})(Typography)
