import React from 'react'
import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import {Paper, Typography} from '@material-ui/core'
import {withStyles} from '@material-ui/styles'
import AuthenticatedPage from '../common/AuthenticatedPage.jsx'
import * as HeaderActions from '../common/redux/actions.header'

const styles = theme => {
  return {
    paper: {
      margin: theme.spacing(10, 10),
      marginRight: 'auto',
      marginLeft: 'auto',
      maxWidth: '32.5rem',
      padding: theme.spacing(10, 1),
      textAlign: 'center',
    },
    underline: {
      position: 'relative',
      textDecoration: 'none',
      '&:after': {
        content: '""',
        position: 'absolute',
        height: '3px',
        bottom: '-7px',
        margin: '0 auto',
        left: '0',
        right: '0',
        maxWidth: '6em',
        background: theme.palette.primary.dark,
      },
    },
  }
}

class Home extends React.Component {
  constructor() {
    super()
    this.state = {
      viewMode: 'grid',
    }
  }

  componentDidMount() {
    const {setHeader} = this.props
    setHeader({hidden: false, title: ''})
  }

  render() {
    const {classes} = this.props

    return (
      <Paper className={classes.paper}>
        <Typography variant="h5" className={classes.underline}>
          Welcome to BaseApp
        </Typography>
        <Typography variant="h1">Your App Starts Here</Typography>
      </Paper>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({...HeaderActions}, dispatch)
}

Home.displayName = 'Home'

export default compose(
  connect(
    null,
    mapDispatchToProps,
  ),
  withStyles(styles, {withTheme: true}),
  AuthenticatedPage,
)(Home)
