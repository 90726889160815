import _ from 'lodash'
import startCourseIcon from '../../assets/icons/start_course.svg'
import cameraIcon from '../../assets/icons/camera_icon.svg'
import fIcon from '../../assets/icons/f_icon.svg'
import bookIcon from '../../assets/icons/book_icon.svg'
import mcIcon from '../../assets/icons/mc_icon.svg'
import imagePlaceholderIcon from '../../assets/icons/image_placeholder_icon.svg'
import appStoreLogo from '../../assets/appstore_logo.png'
import playStoreLogo from '../../assets/playstore_logo.png'
import signatureIcon from '../../assets/icons/signature_icon_cluster.svg'
import SadFace from '../../assets/sad_face.svg'
import SuccessBadge from '../../assets/success_badge.svg'

export const Icons = {
  playStoreLogo,
  appStoreLogo,
  imagePlaceholderIcon,
  startCourseIcon,
  cameraIcon,
  fIcon,
  bookIcon,
  mcIcon,
  signatureIcon,
}

export const Images = {
  SadFace,
  SuccessBadge,
}

export const itemTypes = {
  TRUE_FALSE: 'true-false',
  CHOICE: 'choice',
  TEXT: 'text',
  VIDEO: 'video',
  IMAGE: 'image',
}

export function getItemType(item) {
  if (!_.isEmpty(item?.boolean)) return itemTypes.TRUE_FALSE
  if (!_.isEmpty(item?.choices)) return itemTypes.CHOICE
  if (!_.isEmpty(item?.texts)) return itemTypes.TEXT
  if (!_.isEmpty(item?.videos)) return itemTypes.VIDEO
  if (!_.isEmpty(item?.letterSizeImage)) return itemTypes.IMAGE
  return null
}

export function getItemIcon(item) {
  if (!_.isEmpty(item?.boolean)) return Icons.fIcon
  if (!_.isEmpty(item?.choices)) return Icons.mcIcon
  if (!_.isEmpty(item?.texts)) return Icons.bookIcon
  if (!_.isEmpty(item?.videos)) return Icons.cameraIcon
  if (!_.isEmpty(item?.letterSizeImage)) return Icons.imagePlaceholderIcon
  return ''
}

export function isLastItem(item, items) {
  if (item.id === _.get(_.last(items), 'id')) return true
  return false
}

export function findNextItem(currentItem, items) {
  const order = parseInt(_.get(currentItem, 'order', 0) + 1, 10)
  const remainingItems = _.filter(items, checkedItem => checkedItem.order >= order)

  if (!_.isEmpty(remainingItems)) {
    return _.head(_.sortBy(remainingItems, ['order']))
  }

  const currentGroupIndex = _.findIndex(items, {id: currentItem.id})
  return _.get(items, currentGroupIndex + 1, null)
}

export function findNextGroup(currentGroupIndex, groups) {
  return _.get(groups, currentGroupIndex + 1, null)
}
