import React from 'react'
import CssBaseline from '@material-ui/core/CssBaseline'
import {connect} from 'react-redux'
import _ from 'lodash'
import Helmet from 'react-helmet'
import Header from './common/Header.jsx'
import Footer from './common/Footer.jsx'
import {getProfile} from './profile/redux/actions'
import UserSession from './auth/UserSession'
import NotificationContainer from './common/NotificationContainer.jsx'
import ImageUtils from './utils/ImageUtils'
import PageUtils from './utils/PageUtils'
import {GridContainerPage} from './App.styles.js'

const headerlessLocations = ['confirm-email']
const footerlessLocations = ['confirm-email', 'course']

class App extends React.Component {
  constructor() {
    super()

    this.state = {
      showHeader: true,
      showFooter: true,
    }
  }

  componentWillMount() {
    if (UserSession.getToken() && !this.props.profile) {
      this.props.dispatch(getProfile())
    }
  }

  componentDidMount() {
    this.setHeaderFooterState(this.props.location)

    // Remove the server-side injected CSS.
    const jssStyles = document.getElementById('jss-server-side')
    if (jssStyles && jssStyles.parentNode) {
      jssStyles.parentNode.removeChild(jssStyles)
    }
  }

  componentWillReceiveProps(nextProps) {
    const hasLocationChanged =
      _.get(nextProps, 'location.pathname') !== _.get(this.props, 'location.pathname')
    if (hasLocationChanged) {
      this.setHeaderFooterState(nextProps.location)
    }
  }

  setHeaderFooterState(location) {
    const showHeader = headerlessLocations.indexOf(location.pathname.split('/')[1]) < 0
    const showFooter = footerlessLocations.indexOf(location.pathname.split('/')[1]) < 0

    if (showHeader !== this.state.showHeader || showFooter !== this.state.showFooter) {
      this.setState({showHeader, showFooter})
    }
  }

  render() {
    const {auth, profile, header, dispatch} = this.props
    const {showHeader, showFooter} = this.state

    return (
      <GridContainerPage
        id="appContain"
        className="ba-site"
        container
        direction="column"
        justify="space-between"
        alignItems="center"
        spacing={32}
      >
        <CssBaseline />
        <Helmet
          defaultTitle={PageUtils.getTitle()}
          titleTemplate={`${PageUtils.getTitle()} - %s`}
          meta={[
            {name: 'twitter:card', content: 'summary'},
            {property: 'og:title', content: PageUtils.getTitle()},
            {property: 'og:site_name', content: PageUtils.getTitle()},
            {property: 'og:image', content: ImageUtils.getAppLogoUrl()},
            {
              property: 'og:description',
              content: 'BaseApp from The SilverLogic',
            },
          ]}
        />
        {showHeader ? (
          <Header
            params={header.params}
            dispatch={dispatch}
            auth={auth}
            profile={profile}
            onHomeClick={() => {}}
          />
        ) : null}
        <div
          className="appContent"
          style={{
            marginTop: header.params.hidden ? '20px' : '0px',
            borderRadius: header.params.hidden ? '4px 4px 4px 4px' : '0 0 4px 4px',
          }}
        >
          {this.props.children}
        </div>
        {showFooter ? <Footer /> : null}
        <NotificationContainer />
      </GridContainerPage>
    )
  }
}

App.displayName = 'App'

function mapStateToProps(state) {
  return {
    auth: state.auth,
    profile: _.get(state.profile, 'profile', null),
    header: state.header,
  }
}

// Wrap the component to inject dispatch and state into it
export default connect(mapStateToProps)(App)
