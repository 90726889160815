import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import {withStyles} from '@material-ui/styles'
import {bindActionCreators, compose} from 'redux'
import {
  AppBar,
  Toolbar,
  MenuList,
  MenuItem,
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  Button,
  IconButton,
  Divider,
  Typography,
} from '@material-ui/core'
import AccountCircle from '@material-ui/icons/AccountCircle'
import {Link} from 'react-router'
import {connect} from 'react-redux'
import {fade} from '@material-ui/core/styles/colorManipulator'
import cx from 'classnames'
import {logout} from '../auth/redux/actions'
import {Logo} from './Icons.jsx'
import * as HeaderActions from './redux/actions.header'

const styles = theme => ({
  navbar: {
    backgroundColor: theme.palette.primary.main,
    marginTop: '20px',
    marginLeft: '20px',
    marginRight: '20px',
    width: '-webkit-fill-available',
    borderRadius: '4px 4px 0 0',
    position: 'relative',

    [theme.breakpoints.down('xs')]: {
      margin: 0,
    },
  },

  navbarMoz: {
    width: '-moz-available',
  },

  navbarTitle: {
    flex: 1,
  },
  navbarTitleLink: {
    color: 'white',
    fontSize: '32px',
  },
  navbarProfile: {
    width: '30px',
    height: '30px',
    padding: 0,
    color: theme.palette.common.white,
  },
  navbarDropdown: {
    zIndex: 1,
    minWidth: '12em',
  },
  brandImageContainer: {
    flex: 1,
  },
  brandImage: {
    height: 35,
    width: 'auto',
    filter: `drop-shadow(1px 1px 3px rgba(0, 0, 0, 0.2))`,
  },
  registerBtn: {
    marginLeft: '15px',
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    marginRight: theme.spacing(1),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  },
  searchIcon: {
    color: theme.palette.common.white,
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    color: theme.palette.common.white,
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 120,
      '&:focus': {
        width: 200,
      },
    },
  },
  menuEmail: {
    marginTop: '0',
    marginBottom: '12px',
    minHeight: '0',
  },
  menuName: {
    marginBottom: '0',
    minHeight: '0',
  },

  title: {
    width: '100%',
    height: '100%',
    textAlign: 'center',
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '& p': {
      color: 'white',
      fontWeight: 'bold',
      fontSize: '18px',
      maxWidth: '45vw',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',

      [theme.breakpoints.down('sm')]: {
        fontSize: '14px',
      },
    },
  },
})

export class Header extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      profileMenuOpened: false,
    }

    this.logout = this.logout.bind(this)
    this.handleProfileMenuOpen = this.handleProfileMenuOpen.bind(this)
    this.handleProfileMenuClose = this.handleProfileMenuClose.bind(this)
  }

  handleProfileMenuOpen() {
    this.setState({profileMenuOpened: true})
  }

  handleProfileMenuClose() {
    this.setState({profileMenuOpened: false})
  }

  render() {
    const {classes, params} = this.props
    const profile = _.get(this.props, 'profile')

    let profileName = ''
    let profileEmail = ''

    if (profile) {
      profileName = profile.firstName ? profile.firstName : 'user'
      profileEmail = profile.email
    }

    const isLoggedIn = !!_.get(this.props, 'auth.token')

    return (
      !params.hidden && (
        <AppBar color="default" className={cx(classes.navbar, classes.navbarMoz)} position="static">
          {params.title && (
            <div className={classes.title}>
              <Typography>{params.title}</Typography>
            </div>
          )}
          <Toolbar>
            <div className={classes.brandImageContainer}>
              <IconButton
                id="logoContain"
                onClick={() => this.props.onHomeClick()}
                className={classes.navbarTitleLink}
                component={Link}
                to="/"
              >
                <Logo className={classes.brandImage} />
              </IconButton>
            </div>

            {isLoggedIn ? (
              <React.Fragment>
                <IconButton
                  buttonRef={n => {
                    this.profileButtonRef = n
                  }}
                  onClick={this.handleProfileMenuOpen}
                  title={profileName}
                  className={classes.navbarProfile}
                >
                  <AccountCircle className={classes.navbarProfile} />
                </IconButton>
                <Popper
                  anchorEl={this.profileButtonRef}
                  open={this.state.profileMenuOpened}
                  transition
                  disablePortal
                  className={classes.navbarDropdown}
                >
                  {({TransitionProps, placement}) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                      }}
                    >
                      <Paper>
                        <ClickAwayListener onClickAway={this.handleProfileMenuClose}>
                          <MenuList>
                            <MenuItem className={classes.menuName} button={false}>
                              Hi {profileName}!
                            </MenuItem>
                            <MenuItem className={classes.menuEmail} button={false}>
                              <Typography variant="overline">{profileEmail}</Typography>
                            </MenuItem>
                            <Divider />
                            <MenuItem
                              onClick={e => {
                                this.handleProfileMenuClose(e)
                                this.logout(e)
                              }}
                            >
                              Logout
                            </MenuItem>
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Button component={Link} to="/login">
                  Login
                </Button>
                <Button
                  className={classes.registerBtn}
                  component={Link}
                  to="/signup"
                  variant="contained"
                  color="secondary"
                >
                  Register
                </Button>
              </React.Fragment>
            )}
          </Toolbar>
        </AppBar>
      )
    )
  }

  logout() {
    this.props.dispatch(logout())
  }
}

Header.displayName = 'Header'
Header.propTypes = {
  classes: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  onHomeClick: PropTypes.func.isRequired,
}
Header.contextTypes = {
  router: PropTypes.object.isRequired,
}

function mapStateToProps(state) {
  return {
    searchText: _.get(state, 'home.query.q', ''),
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...HeaderActions,
    },
    dispatch,
  )
}

export default compose(
  withStyles(styles),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(Header)
