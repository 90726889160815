import React, {useEffect, useState} from 'react'
import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import {withStyles} from '@material-ui/styles'
import _ from 'lodash'
import {Typography, Grid, Button} from '@material-ui/core'
import moment from 'moment'
import styles from './styles/CourseSummary.index.styles.js'
import * as HeaderActions from '../common/redux/actions.header'
import * as Actions from './redux/actions'
import * as NotificationActions from '../common/redux/actions.notifications'
import CoursesApi from './api'
import AlfLinearProgress from '../common/AlfLinearProgress'
import AuthenticatedPage from '../common/AuthenticatedPage.jsx'
import {isLastItem, Images} from './Utils'

function CourseSummary({
  getCourse,
  setHeader,
  params,
  courses,
  createError,
  classes,
  router,
  createNotificationFromError,
  location,
}) {
  const {isFetching, course, lastCompletedCourseItem} = courses

  const [courseSummary, setCourseSummary] = useState(null)

  if (_.get(course, 'task')) {
    const lastCompletedItemId = _.get(course, 'lastCompletedCourseItem', 0)
    const lastCompletedItem =
      lastCompletedCourseItem || _.find(course?.items, {id: parseInt(lastCompletedItemId, 10)})

    if (!lastCompletedItem || !isLastItem(lastCompletedItem, course?.items)) {
      console.log(lastCompletedItem)
      createError('Course Not finished.')
      router.push(`/course/${_.get(params, 'id')}`)
      return null
    }
  }

  const completeCourse = async () => {
    try {
      const payload = {}

      if (location?.state?.signature) {
        payload.signature = location?.state?.signature
      }
      const response = await CoursesApi.completeCourse(_.get(params, 'id'), payload)
      setCourseSummary({
        score: response?.score,
        total: response?.total || 0,
        isApproved: response?.isApproved,
        completedDate: `${moment().format('MM/DD/YYYY')} at ${moment().format('h:mm a')}`,
      })
    } catch (e) {
      console.warn(e)
      if (e.response.status !== 400) {
        createError(`${e.response.status} - ${e.response.statusText}`)
        return
      }
      createNotificationFromError(e)
    }
  }

  useEffect(() => {
    setHeader({hidden: false})
    getCourse(_.get(params, 'id'))
    completeCourse()
  }, [])

  return (
    <div className={classes.root}>
      {isFetching && <AlfLinearProgress />}
      {!_.isEmpty(course) && courseSummary && (
        <Grid container className={classes.mainCont}>
          <Grid item xs={12} className={classes.iconCont}>
            <img className={classes.image} src={Images.SuccessBadge} alt="Summary status" />
          </Grid>
          <Grid item xs={12} style={{textAlign: 'center'}}>
            <Typography className={classes.mainHeader}>Congratulations!</Typography>
            <Typography className={classes.mainContent}>Your Course is complete</Typography>
            <Typography className={classes.completedDate}>
              {courseSummary?.completedDate}
            </Typography>
            <Button
              className={classes.doneButton}
              onClick={() => window.close()}
              style={{backgroundColor: '#19AF5B'}}
            >
              Done
            </Button>
          </Grid>
        </Grid>
      )}
    </div>
  )
}

CourseSummary.displayName = 'CourseSummary'

function mapStateToProps(state) {
  return {
    courses: _.get(state, 'course'),
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({...Actions, ...HeaderActions, ...NotificationActions}, dispatch)
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withStyles(styles, {withTheme: true}),
  AuthenticatedPage,
)(CourseSummary)
