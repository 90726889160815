export default theme => ({
  root: {
    width: '100%',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },

  mainCont: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },

  taskImage: {
    width: '100%',
    height: '25vh',
    objectFit: 'cover',
    boxShadow: '0 2px 4px 0 rgba(0,0,0,0.2)',
    // borderRadius: '4px 4px 0 0',
  },

  contentCont: {
    display: 'flex',
    flex: 1,
    paddingLeft: '30px',
    paddingRight: '30px',
    paddingTop: '30px',
    paddingBottom: '30px',
    flexDirection: 'column',
  },

  topRow: {
    display: 'flex',
    width: '100%',
    minHeight: '130px',
    flexDirection: 'row',
  },

  topRowLeftColumn: {
    flex: 1,
    '& h1': {
      color: '#69788C',
      margin: 0,
      fontWeight: 'bold',
      fontSize: '18px',
      marginBottom: '20px',
    },

    '& p': {
      fontSize: '14px',
      fontWeight: 'bold',
      color: '#69788C',

      '& span': {
        color: '#797879',
      },
    },
  },

  topRowRightColumn: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justfiyContent: 'center',
    alignItems: 'center',
    '& p': {
      color: '#1973E8',
      fontSize: '14px',
      fontWeight: 'bold',
    },
  },

  bottomRow: {
    display: 'flex',
    width: '100%',
    flex: 1,
    flexDirection: 'row',

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },

  bottomRowLeftColumn: {
    flex: 1,

    '& h2': {
      color: '#69788C',
      margin: 0,
      fontWeight: 'bold',
      fontSize: '18px',
      marginBottom: '20px',
    },

    '& p': {
      fontSize: '14px',
      color: '#797879',
    },
  },

  bottomRowRightColumn: {
    flex: 1,
    paddingLeft: '20px',

    [theme.breakpoints.down('sm')]: {
      paddingLeft: '0px',
      marginTop: '15px',
    },
  },

  sectionsListCont: {
    padding: '13px',
    '& h2': {
      color: '#69788C',
      margin: 0,
      fontWeight: 'bold',
      fontSize: '18px',
      marginBottom: '20px',
    },
  },

  sectionsListScroll: {
    maxHeight: '320px',
    overflowY: 'scroll',
  },

  sectionListItemCont: {
    paddingLeft: '15px',
    paddingRight: '15px',
    width: '100%',
    display: 'flex',

    '& .MuiButton-label': {
      alignItems: 'left',
      justifyContent: 'left',
    },

    '& p': {
      fontSize: '14px',
      color: '#797879',
      width: '90%',
      textAlign: 'left',
    },

    '& img': {
      marginRight: '20px',
      width: '22px',
    },
    '& svg': {
      color: '#19AF5B',
    },
  },

  infoText: {
    color: '#797879',
    marginTop: '20px',
    textAlign: 'center',
  },

  storeIcons: {
    width: '100%',
    textAlign: 'center',
    marginTop: '10px',
  },
  storeIcon: {
    width: '100px',
    marginLeft: '10px',
  },
})
