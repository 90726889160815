import React from 'react'

const socialMedia = {
  email: {
    label: 'Email',
    svg: (
      <path d="M22 6c0-1.1-.9-2-2-2H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6zm-2 0l-8 4.99L4 6h16zm0 12H4V8l8 5 8-5v10z" />
    ),
    fontAwesome: 'fa-envelope',
  },
  website: {
    label: 'Website',
    svg: (
      <path d="M19 4H5c-1.11 0-2 .9-2 2v12c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.89-2-2-2zm0 14H5V8h14v10z" />
    ),
    fontAwesome: 'fa-link',
  },
  facebook: {
    label: 'Facebook',
    svg: ``,
    fontAwesome: 'fa-facebook-official',
  },
  instagram: {
    label: 'Instagram',
    svg: ``,
    fontAwesome: 'fa-instagram',
  },
  linkedin: {
    label: 'LinkedIn',
    svg: ``,
    fontAwesome: 'fa-linkedin-square',
  },
  googlePlus: {
    label: 'Google+',
    svg: ``,
    fontAwesome: 'fa-google-plus-official',
  },
  twitter: {
    label: 'Twitter',
    svg: ``,
    fontAwesome: 'fa-twitter',
  },
  playStoreURL: 'https://play.google.com/store/apps/details?id=com.alf.alfBossLms&hl=en&gl=US',
  appStoreURL: 'https://apps.apple.com/us/app/alf-boss-trainings/id1476725428',
}

export {socialMedia}
