import React from 'react'
import LinearProgress from '@material-ui/core/LinearProgress'
import {withStyles} from '@material-ui/core/styles'

function AlfLinearProgress(props) {
  const {classes} = props
  return (
    <LinearProgress
      {...props}
      classes={{colorPrimary: classes.colorPrimary, barColorPrimary: classes.barColorPrimary}}
    />
  )
}

const styles = () => ({
  colorPrimary: {
    backgroundColor: '#1973E8',
  },
  barColorPrimary: {
    backgroundColor: '#D8D8D8',
  },
})

export default withStyles(styles)(AlfLinearProgress)
