export const PROFILE_GET = 'PROFILE_GET'
export const PROFILE_GET_SUCCESS = 'PROFILE_GET_SUCCESS'
export const PROFILE_GET_FAILURE = 'PROFILE_GET_FAILURE'

export const PROFILE_CHANGE_PASSWORD = 'PROFILE_CHANGE_PASSWORD'
export const PROFILE_CHANGE_PASSWORD_SUCCESS = 'PROFILE_CHANGE_PASSWORD_SUCCESS'
export const PROFILE_CHANGE_PASSWORD_FAILURE = 'PROFILE_CHANGE_PASSWORD_FAILURE'

export const PROFILE_CHANGE_PASSWORD_UPDATE = 'PROFILE_CHANGE_PASSWORD_UPDATE'

export const PROFILE_CREATE = 'PROFILE_CREATE'
export const PROFILE_CREATE_SUCCESS = 'PROFILE_CREATE_SUCCESS'
export const PROFILE_CREATE_FAILURE = 'PROFILE_CREATE_FAILURE'
export const PROFILE_CREATE_INIT = 'PROFILE_CREATE_INIT'

export const PROFILE_EDIT_INIT = 'PROFILE_EDIT_INIT'
export const PROFILE_UPDATE = 'PROFILE_UPDATE'
export const PROFILE_SAVE = 'PROFILE_SAVE'
export const PROFILE_SAVE_SUCCESS = 'PROFILE_SAVE_SUCCESS'
export const PROFILE_SAVE_FAILURE = 'PROFILE_SAVE_FAILURE'

export const PROFILE_TOGGLE_EDIT = 'PROFILE_TOGGLE_EDIT'
