import _ from 'lodash'
import settings from '../../../config/settings'
import UserSession from './UserSession'
import axios from '../api/axios'

export default class AuthApi {
  static login(username, password) {
    return axios.post(`${settings.apiBaseURL}/login/`, {
      username,
      password,
      clientId: '1CvcYng7nZYdEh2yG3YMGwYfFcHmag',
    })
  }

  static socialLoginOAuth1ObtainRequestToken(provider, redirectUri) {
    return axios.post(`${settings.apiBaseURL}/social-auth`, {
      provider,
      redirectUri,
    })
  }

  static socialLoginOAuth1(payload) {
    return axios.post(`${settings.apiBaseURL}/social-auth`, _.omitBy(payload, p => !p))
  }

  static socialLoginOAuth2(provider, code, redirectUri, email) {
    return axios.post(
      `${settings.apiBaseURL}/social-auth`,
      _.omitBy({provider, code, redirectUri, email}, p => !p),
    )
  }

  static logout() {
    UserSession.setToken(null)
  }

  static register(email, password) {
    return axios.post(`${settings.apiBaseURL}/register`, {
      email,
      password,
    })
  }
}
