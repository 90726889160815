import {withStyles} from '@material-ui/styles'
import {Typography} from '@material-ui/core'

export default theme => ({
  root: {
    width: '100%',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    position: 'relative',
    paddingBottom: '43px',
  },

  textCont: {
    padding: '150px 50px',
    border: '1px solid #D8D8D8',
    borderRadius: '5px',
    [theme.breakpoints.down('sm')]: {
      padding: '75px 25px',
    },
  },
  ButtonCont: {
    textAlign: 'center',
    margin: '40px 20px',
    justifyContent: 'center',
    alignItems: 'center',
  },

  mainCont: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    alignItems: 'center',

    justifyContent: 'center',
  },
  nextButton: {
    marginTop: 75,
    background: '#19AF5B !important',
  },
  imageCont: {
    padding: '16px',
    wordBreak: 'break-word',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    [theme.breakpoints.down('sm')]: {
      padding: '5px',
    },
  },

  imageBox: {
    padding: '20px',
    minWidth: '600px',
    '& img': {
      objectFit: 'contain !important',
    },

    [theme.breakpoints.down('sm')]: {
      padding: '10px',
      minWidth: '80vw',
    },

    [theme.breakpoints.down('sm')]: {
      padding: '10px',
      minWidth: '80vw',

      '& img': {
        objectFit: 'inherit !important',
      },
    },
  },

  button: {
    [theme.breakpoints.down('xs')]: {
      marginRight: '0px',
      marginBottom: '20px',
    },
    textAlign: 'center',
    paddingLeft: '80px',
    paddingRight: '80px',
    background: '#19AF5B',
    borderRadius: '21.5px',
    height: '43px',
    width: '240px',
    color: 'white',
    marginRight: '40px',

    '&:hover': {
      backgroundColor: '#15934c',
    },
  },
  falseButton: {
    background: '#E84A34',
    '&:hover': {
      backgroundColor: '#E84A34 !important',
    },
  },
  selected: {
    background: '#69788C !important',
    '&:hover': {
      backgroundColor: '#15934c',
    },
  },
  buttonDisabled: {
    background: '#D3D3D3',
    '&:hover': {
      backgroundColor: '#D3D3D3',
    },
  },
})

export const ChoiceTypography = withStyles({
  root: {
    color: '#69788C',
    fontFamily: 'Lato',
    fontSize: '18px',
    fontWeight: 'bold',
    letterSpacing: 0,
    lineHeight: '22px',
  },
})(Typography)
