import {call, put, all, takeEvery} from 'redux-saga/effects'
import CoursesApi from '../api'
import * as AuthActions from '../../auth/redux/actions'
import * as Actions from './actions'
import * as HeaderActions from '../../common/redux/actions.header'
import * as Types from './actions.types'

export default function* courseFlow() {
  yield all([takeEvery(Types.COURSE_GET, getCourse)])
  yield all([takeEvery(Types.COURSE_ITEM_START, startCourseItem)])
}

function* getCourse(action) {
  const {courseId} = action
  try {
    const response = yield call(CoursesApi.openCourse, courseId)

    yield put(Actions.getCourseSuccess(response))

    if (window && window.location.pathname !== '/') {
      yield put(HeaderActions.setHeader({hidden: false, title: response?.name || ''}))
    }
  } catch (error) {
    yield put(Actions.getCourseFailure(error))
    if (error.response.status === 401) {
      yield put(AuthActions.logout())
    }
  }
}

function* startCourseItem(action) {
  const {itemId} = action

  try {
    yield call(CoursesApi.startCourseItem, itemId)
    yield put(Actions.startCourseItemSuccess())
  } catch (error) {
    yield put(Actions.startCourseItemFailure(error))
    if (error.response.status === 401) {
      yield put(AuthActions.logout())
    }
  }
}
