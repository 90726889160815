import React from 'react'
import PropTypes from 'prop-types'
import {Link} from 'react-router'
import Helmet from 'react-helmet'
import {Card, CardContent, Typography, Input, Grid, Button} from '@material-ui/core'
import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import InputValidator from '../common/InputValidator.jsx'
import validatedComponent from '../common/ValidatedComponent.jsx'
import * as Actions from './redux/actions.forgotPw'

class FogotPasswordConsume extends React.Component {
  constructor() {
    super()

    this.state = {
      password: '',
      confirmPassword: '',
    }
    this.validatePasswords = this.validatePasswords.bind(this)
  }

  componentDidMount() {
    if (!this.props.params.token) {
      this.context.router.replace('/forgot')
    }
  }

  validatePasswords() {
    const {password, confirmPassword} = this.state
    if (password !== confirmPassword) {
      return 'Both passwords must be equal'
    }
  }

  onResetClick(e) {
    e.preventDefault()

    const {
      isComponentValid,
      resetForgotPw,
      params: {token},
    } = this.props
    if (isComponentValid()) {
      resetForgotPw(token, this.state.password)
    }
  }

  render() {
    const {password, confirmPassword} = this.state
    const {isReset} = this.props
    return (
      <div className="registrationPage">
        <Helmet title="Reset password" />
        <Card className="registrationPage__card registrationPage__card--centered">
          <CardContent className="registrationPage__card__content">
            <Typography
              component="h2"
              variant="h2"
              className="registrationPage__title"
              gutterBottom
            >
              Reset password
            </Typography>
            {!isReset ? (
              <form noValidate>
                <Grid container spacing={24}>
                  <Grid item xs={12}>
                    <InputValidator
                      fieldName="New Password"
                      shouldValidateOnBlur={true}
                      customValidation={() => {
                        return this.validatePasswords()
                      }}
                    >
                      <Input
                        type="password"
                        required
                        value={password}
                        placeholder="enter new password"
                        onChange={e => this.setState({password: e.target.value})}
                      />
                    </InputValidator>
                  </Grid>

                  <Grid item xs={12}>
                    <InputValidator
                      fieldName="Confirm Password"
                      required
                      shouldValidateOnBlur={true}
                      customValidation={() => {
                        return this.validatePasswords()
                      }}
                    >
                      <Input
                        type="password"
                        required
                        value={confirmPassword}
                        placeholder="retype new password"
                        onChange={e => this.setState({confirmPassword: e.target.value})}
                      />
                    </InputValidator>
                  </Grid>

                  <Grid item xs={12}>
                    <Button onClick={this.onResetClick.bind(this)} variant="contained">
                      Reset Password
                    </Button>
                  </Grid>
                </Grid>
              </form>
            ) : (
              <Grid container spacing={24}>
                <Grid item xs={12}>
                  <p>
                    Your new password has been successfully set. You can now{' '}
                    <Link to="login">log in</Link>.
                  </p>
                </Grid>
              </Grid>
            )}
          </CardContent>
        </Card>
      </div>
    )
  }
}

FogotPasswordConsume.displayName = 'FogotPasswordConsume'

FogotPasswordConsume.propTypes = {
  params: PropTypes.shape({
    token: PropTypes.string.isRequired,
  }),
}

FogotPasswordConsume.contextTypes = {
  router: PropTypes.object.isRequired,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch)
}

function mapStateToProps(state) {
  return {...state.forgotPw}
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(validatedComponent(FogotPasswordConsume))
