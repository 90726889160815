import _ from 'lodash'
import * as Types from './actions.types'
import UserSession from '../UserSession'
import * as ErrorUtils from '../../utils/ErrorUtils'

export default function auth(state = getInitialState(), action) {
  switch (action.type) {
    case Types.LOGIN:
    case Types.SOCIAL_LOGIN_OAUTH2:
    case Types.SOCIAL_LOGIN_OAUTH1_STEP1:
      return {
        ...state,
        ...getOnLoginStart(action),
      }
    case Types.SOCIAL_LOGIN_OAUTH1_STEP1_SUCCESS: {
      const {oauthToken, oauthTokenSecret} = action
      return {
        ...state,
        isFetching: false,
        oauthToken,
        oauthTokenSecret,
      }
    }
    case Types.LOGIN_SUCCESS:
      return {
        ...getInitialState(),
        token: action.token,
      }
    case Types.LOGIN_FAILURE: {
      const errors = ErrorUtils.getApiErrors(action.error)
      return {
        ...state,
        isFetching: false,
        errors,
        isNoEmailProvidedError: _.get(errors, 'email') === 'no_email_provided',
      }
    }
    case Types.SET_TOKEN:
      return {
        ...state,
        token: action.token,
      }
    case Types.LOGOUT:
      return {
        ...state,
        token: null,
      }
    default:
      return state
  }
}

export function getInitialState() {
  return {
    isFetching: false,
    errors: {},
    token: UserSession.getToken(),
  }
}

function getOnLoginStart(action) {
  return {
    isFetching: true,
    isNoEmailProvidedError: false,
    errors: {},
    provider: action.provider,
  }
}
