import reduceReducers from 'reduce-reducers'
import * as Types from './actions.types'

function course(state = getInitialState(null), action) {
  switch (action.type) {
    case Types.COURSE_GET:
      return {
        ...state,
        isFetching: true,
      }
    case Types.COURSE_GET_SUCCESS:
      return {
        ...state,
        isFetching: false,
        course: action.payload,
      }
    case Types.COURSE_GET_FAILURE:
      return {
        ...state,
        isFetching: false,
        course: null,
      }
    case Types.COURSE_SET_LAST_COMPLETED_ITEM:
      return {
        ...state,
        lastCompletedCourseItem: action.item,
      }
    case Types.COURSE_ITEM_START:
      return {
        ...state,
        isStartingCourse: true,
      }
    case Types.COURSE_ITEM_START_SUCCESS:
      return {
        ...state,
        isStartingCourse: false,
      }
    case Types.COURSE_ITEM_START_FAILURE:
      return {
        ...state,
        isStartingCourse: false,
      }
    default:
      return state
  }
}

function getInitialState(course = null) {
  return {
    isFetching: false,
    isStartingCourse: false,
    errors: {},
    lastCompletedCourseItem: null,
    course,
  }
}

const reducer = reduceReducers((state, action) => course(state, action))

export default reducer
