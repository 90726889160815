import * as HeaderActions from './actions.header.types'

const initialState = {visible: false, params: {}}

export default function header(state = initialState, action) {
  switch (action.type) {
    case HeaderActions.SET_HEADER:
    case HeaderActions.HIDE_HEADER:
      return {...action.data, params: {...(action.data.params || state.params)}}
    default:
      return state
  }
}
