import React, {useState} from 'react'
import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import {withStyles} from '@material-ui/styles'
import {Paper, Button} from '@material-ui/core'
import _ from 'lodash'
import Image from 'material-ui-image'
import {Lightbox} from 'react-modal-image'
import * as Actions from './redux/actions'
import * as HeaderActions from '../common/redux/actions.header'
import styles from './styles/CourseItem.image.styles.js'

function CourseItemImage({
  classes,
  item,
  hasMinDurationPassed,
  onCourseItemComplete,
  isCompletingItem,
}) {
  const [imagePreviewOpen, setImagePreviewOpen] = useState(false)
  const [currentImageIndex, setCurrentImageIndex] = useState(0)

  const images = _.get(item, 'letterSizeImage', [])
  const currentImage = images[currentImageIndex]?.image?.fullSize

  const handleNext = () => {
    if (currentImageIndex < images.length - 1) {
      setCurrentImageIndex(prevIndex => prevIndex + 1)
    } else {
      onCourseItemComplete(item, null)
    }
  }

  return (
    <div className={classes.root}>
      <div className={classes.mainCont}>
        <div className={classes.imageCont}>
          <Paper className={classes.imageBox}>
            {currentImage ? (
              <Image
                onClick={() => setImagePreviewOpen(true)}
                src={currentImage}
                alt="Course item"
              />
            ) : (
              <div>No images available</div>
            )}
            {imagePreviewOpen && (
              <Lightbox large={currentImage} onClose={() => setImagePreviewOpen(false)} />
            )}
          </Paper>
        </div>
        <div className={hasMinDurationPassed ? classes.nextButton : classes.nextButtonDisabled}>
          <Button disabled={!hasMinDurationPassed || isCompletingItem} onClick={handleNext}>
            Next
          </Button>
        </div>
      </div>
    </div>
  )
}

CourseItemImage.displayName = 'CourseItemImage'

function mapStateToProps(state) {
  return {
    courses: _.get(state, 'course'),
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({...Actions, ...HeaderActions}, dispatch)
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withStyles(styles, {withTheme: true}),
)(CourseItemImage)
